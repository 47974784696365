export const demographics = [
  {
    year: 1960,
    country: "China",
    "0 - 20": 0.4802,
    "20 - 40": 0.277,
    "40 - 60": 0.1809,
    "60 - 80": 0.06,
    "80+": 0.002,
  },
  {
    year: 1960,
    country: "Germany",
    "0 - 20": 0.2848,
    "20 - 40": 0.2843,
    "40 - 60": 0.2589,
    "60 - 80": 0.1562,
    "80+": 0.0158,
  },
  {
    year: 1960,
    country: "Italy",
    "0 - 20": 0.3257,
    "20 - 40": 0.3024,
    "40 - 60": 0.2331,
    "60 - 80": 0.1251,
    "80+": 0.0138,
  },
  {
    year: 1960,
    country: "Japan",
    "0 - 20": 0.4024,
    "20 - 40": 0.3229,
    "40 - 60": 0.1878,
    "60 - 80": 0.0799,
    "80+": 0.0069,
  },
  {
    year: 1960,
    country: "Korea, Rep.",
    "0 - 20": 0.5256,
    "20 - 40": 0.2757,
    "40 - 60": 0.1442,
    "60 - 80": 0.052,
    "80+": 0.0026,
  },
  {
    year: 1960,
    country: "Nigeria",
    "0 - 20": 0.5196,
    "20 - 40": 0.2856,
    "40 - 60": 0.1454,
    "60 - 80": 0.0481,
    "80+": 0.0014,
  },
  {
    year: 1960,
    country: "South Africa",
    "0 - 20": 0.5023,
    "20 - 40": 0.2777,
    "40 - 60": 0.1584,
    "60 - 80": 0.0578,
    "80+": 0.0037,
  },
  {
    year: 1960,
    country: "United Kingdom",
    "0 - 20": 0.3009,
    "20 - 40": 0.2628,
    "40 - 60": 0.2667,
    "60 - 80": 0.1501,
    "80+": 0.0195,
  },
  {
    year: 1960,
    country: "United States",
    "0 - 20": 0.3804,
    "20 - 40": 0.2581,
    "40 - 60": 0.2297,
    "60 - 80": 0.1178,
    "80+": 0.014,
  },
  {
    year: 1961,
    country: "China",
    "0 - 20": 0.4818,
    "20 - 40": 0.2759,
    "40 - 60": 0.1801,
    "60 - 80": 0.06,
    "80+": 0.0022,
  },
  {
    year: 1961,
    country: "Germany",
    "0 - 20": 0.2842,
    "20 - 40": 0.2869,
    "40 - 60": 0.2537,
    "60 - 80": 0.1585,
    "80+": 0.0168,
  },
  {
    year: 1961,
    country: "Italy",
    "0 - 20": 0.3254,
    "20 - 40": 0.301,
    "40 - 60": 0.2328,
    "60 - 80": 0.1261,
    "80+": 0.0146,
  },
  {
    year: 1961,
    country: "Japan",
    "0 - 20": 0.3963,
    "20 - 40": 0.3262,
    "40 - 60": 0.189,
    "60 - 80": 0.0811,
    "80+": 0.0074,
  },
  {
    year: 1961,
    country: "Korea, Rep.",
    "0 - 20": 0.5291,
    "20 - 40": 0.2734,
    "40 - 60": 0.1434,
    "60 - 80": 0.0514,
    "80+": 0.0028,
  },
  {
    year: 1961,
    country: "Nigeria",
    "0 - 20": 0.5208,
    "20 - 40": 0.2848,
    "40 - 60": 0.1449,
    "60 - 80": 0.0479,
    "80+": 0.0015,
  },
  {
    year: 1961,
    country: "South Africa",
    "0 - 20": 0.5041,
    "20 - 40": 0.2766,
    "40 - 60": 0.1579,
    "60 - 80": 0.0575,
    "80+": 0.0039,
  },
  {
    year: 1961,
    country: "United Kingdom",
    "0 - 20": 0.3041,
    "20 - 40": 0.2601,
    "40 - 60": 0.2646,
    "60 - 80": 0.1508,
    "80+": 0.0204,
  },
  {
    year: 1961,
    country: "United States",
    "0 - 20": 0.3841,
    "20 - 40": 0.2545,
    "40 - 60": 0.2287,
    "60 - 80": 0.118,
    "80+": 0.0147,
  },
  {
    year: 1962,
    country: "China",
    "0 - 20": 0.487,
    "20 - 40": 0.2731,
    "40 - 60": 0.178,
    "60 - 80": 0.0595,
    "80+": 0.0025,
  },
  {
    year: 1962,
    country: "Germany",
    "0 - 20": 0.2852,
    "20 - 40": 0.2871,
    "40 - 60": 0.2494,
    "60 - 80": 0.1609,
    "80+": 0.0174,
  },
  {
    year: 1962,
    country: "Italy",
    "0 - 20": 0.3258,
    "20 - 40": 0.2976,
    "40 - 60": 0.234,
    "60 - 80": 0.1274,
    "80+": 0.0152,
  },
  {
    year: 1962,
    country: "Japan",
    "0 - 20": 0.3901,
    "20 - 40": 0.3292,
    "40 - 60": 0.1906,
    "60 - 80": 0.0825,
    "80+": 0.0076,
  },
  {
    year: 1962,
    country: "Korea, Rep.",
    "0 - 20": 0.5307,
    "20 - 40": 0.2717,
    "40 - 60": 0.144,
    "60 - 80": 0.0508,
    "80+": 0.0029,
  },
  {
    year: 1962,
    country: "Nigeria",
    "0 - 20": 0.5214,
    "20 - 40": 0.2845,
    "40 - 60": 0.1448,
    "60 - 80": 0.0477,
    "80+": 0.0016,
  },
  {
    year: 1962,
    country: "South Africa",
    "0 - 20": 0.5059,
    "20 - 40": 0.2756,
    "40 - 60": 0.1574,
    "60 - 80": 0.0571,
    "80+": 0.004,
  },
  {
    year: 1962,
    country: "United Kingdom",
    "0 - 20": 0.3066,
    "20 - 40": 0.2572,
    "40 - 60": 0.2632,
    "60 - 80": 0.152,
    "80+": 0.0209,
  },
  {
    year: 1962,
    country: "United States",
    "0 - 20": 0.3865,
    "20 - 40": 0.2517,
    "40 - 60": 0.2281,
    "60 - 80": 0.1184,
    "80+": 0.0153,
  },
  {
    year: 1963,
    country: "China",
    "0 - 20": 0.4939,
    "20 - 40": 0.2696,
    "40 - 60": 0.1752,
    "60 - 80": 0.0588,
    "80+": 0.0025,
  },
  {
    year: 1963,
    country: "Germany",
    "0 - 20": 0.2874,
    "20 - 40": 0.2857,
    "40 - 60": 0.2458,
    "60 - 80": 0.1634,
    "80+": 0.0177,
  },
  {
    year: 1963,
    country: "Italy",
    "0 - 20": 0.3264,
    "20 - 40": 0.293,
    "40 - 60": 0.236,
    "60 - 80": 0.129,
    "80+": 0.0156,
  },
  {
    year: 1963,
    country: "Japan",
    "0 - 20": 0.3836,
    "20 - 40": 0.3321,
    "40 - 60": 0.1925,
    "60 - 80": 0.084,
    "80+": 0.0078,
  },
  {
    year: 1963,
    country: "Korea, Rep.",
    "0 - 20": 0.531,
    "20 - 40": 0.2706,
    "40 - 60": 0.1451,
    "60 - 80": 0.0504,
    "80+": 0.0029,
  },
  {
    year: 1963,
    country: "Nigeria",
    "0 - 20": 0.5215,
    "20 - 40": 0.2845,
    "40 - 60": 0.145,
    "60 - 80": 0.0474,
    "80+": 0.0017,
  },
  {
    year: 1963,
    country: "South Africa",
    "0 - 20": 0.5078,
    "20 - 40": 0.2748,
    "40 - 60": 0.1567,
    "60 - 80": 0.0567,
    "80+": 0.004,
  },
  {
    year: 1963,
    country: "United Kingdom",
    "0 - 20": 0.3085,
    "20 - 40": 0.2545,
    "40 - 60": 0.2622,
    "60 - 80": 0.1536,
    "80+": 0.0212,
  },
  {
    year: 1963,
    country: "United States",
    "0 - 20": 0.3877,
    "20 - 40": 0.2497,
    "40 - 60": 0.2278,
    "60 - 80": 0.1191,
    "80+": 0.0158,
  },
  {
    year: 1964,
    country: "China",
    "0 - 20": 0.5,
    "20 - 40": 0.2666,
    "40 - 60": 0.1729,
    "60 - 80": 0.058,
    "80+": 0.0025,
  },
  {
    year: 1964,
    country: "Germany",
    "0 - 20": 0.2896,
    "20 - 40": 0.2839,
    "40 - 60": 0.2425,
    "60 - 80": 0.1663,
    "80+": 0.0177,
  },
  {
    year: 1964,
    country: "Italy",
    "0 - 20": 0.3269,
    "20 - 40": 0.2886,
    "40 - 60": 0.2378,
    "60 - 80": 0.131,
    "80+": 0.0158,
  },
  {
    year: 1964,
    country: "Japan",
    "0 - 20": 0.3766,
    "20 - 40": 0.3351,
    "40 - 60": 0.1949,
    "60 - 80": 0.0857,
    "80+": 0.0078,
  },
  {
    year: 1964,
    country: "Korea, Rep.",
    "0 - 20": 0.531,
    "20 - 40": 0.2698,
    "40 - 60": 0.1461,
    "60 - 80": 0.0501,
    "80+": 0.003,
  },
  {
    year: 1964,
    country: "Nigeria",
    "0 - 20": 0.5213,
    "20 - 40": 0.2846,
    "40 - 60": 0.1453,
    "60 - 80": 0.0472,
    "80+": 0.0016,
  },
  {
    year: 1964,
    country: "South Africa",
    "0 - 20": 0.5095,
    "20 - 40": 0.274,
    "40 - 60": 0.1562,
    "60 - 80": 0.0565,
    "80+": 0.0038,
  },
  {
    year: 1964,
    country: "United Kingdom",
    "0 - 20": 0.31,
    "20 - 40": 0.2525,
    "40 - 60": 0.2609,
    "60 - 80": 0.1554,
    "80+": 0.0213,
  },
  {
    year: 1964,
    country: "United States",
    "0 - 20": 0.388,
    "20 - 40": 0.2486,
    "40 - 60": 0.2275,
    "60 - 80": 0.1199,
    "80+": 0.016,
  },
  {
    year: 1965,
    country: "China",
    "0 - 20": 0.5044,
    "20 - 40": 0.2648,
    "40 - 60": 0.1713,
    "60 - 80": 0.0573,
    "80+": 0.0022,
  },
  {
    year: 1965,
    country: "Germany",
    "0 - 20": 0.2916,
    "20 - 40": 0.2823,
    "40 - 60": 0.2392,
    "60 - 80": 0.1693,
    "80+": 0.0176,
  },
  {
    year: 1965,
    country: "Italy",
    "0 - 20": 0.3267,
    "20 - 40": 0.2853,
    "40 - 60": 0.2389,
    "60 - 80": 0.1334,
    "80+": 0.0157,
  },
  {
    year: 1965,
    country: "Japan",
    "0 - 20": 0.3688,
    "20 - 40": 0.3383,
    "40 - 60": 0.1977,
    "60 - 80": 0.0876,
    "80+": 0.0076,
  },
  {
    year: 1965,
    country: "Korea, Rep.",
    "0 - 20": 0.5309,
    "20 - 40": 0.2695,
    "40 - 60": 0.1465,
    "60 - 80": 0.0501,
    "80+": 0.003,
  },
  {
    year: 1965,
    country: "Nigeria",
    "0 - 20": 0.5211,
    "20 - 40": 0.2848,
    "40 - 60": 0.1456,
    "60 - 80": 0.0471,
    "80+": 0.0014,
  },
  {
    year: 1965,
    country: "South Africa",
    "0 - 20": 0.5109,
    "20 - 40": 0.2731,
    "40 - 60": 0.1557,
    "60 - 80": 0.0566,
    "80+": 0.0036,
  },
  {
    year: 1965,
    country: "United Kingdom",
    "0 - 20": 0.3109,
    "20 - 40": 0.2515,
    "40 - 60": 0.2592,
    "60 - 80": 0.1574,
    "80+": 0.021,
  },
  {
    year: 1965,
    country: "United States",
    "0 - 20": 0.3874,
    "20 - 40": 0.2485,
    "40 - 60": 0.2272,
    "60 - 80": 0.1209,
    "80+": 0.016,
  },
  {
    year: 1966,
    country: "China",
    "0 - 20": 0.5105,
    "20 - 40": 0.261,
    "40 - 60": 0.1686,
    "60 - 80": 0.0575,
    "80+": 0.0025,
  },
  {
    year: 1966,
    country: "Germany",
    "0 - 20": 0.2946,
    "20 - 40": 0.2809,
    "40 - 60": 0.2353,
    "60 - 80": 0.1708,
    "80+": 0.0185,
  },
  {
    year: 1966,
    country: "Italy",
    "0 - 20": 0.3262,
    "20 - 40": 0.2831,
    "40 - 60": 0.239,
    "60 - 80": 0.1351,
    "80+": 0.0166,
  },
  {
    year: 1966,
    country: "Japan",
    "0 - 20": 0.3613,
    "20 - 40": 0.3412,
    "40 - 60": 0.2005,
    "60 - 80": 0.0889,
    "80+": 0.0081,
  },
  {
    year: 1966,
    country: "Korea, Rep.",
    "0 - 20": 0.529,
    "20 - 40": 0.2708,
    "40 - 60": 0.1465,
    "60 - 80": 0.0503,
    "80+": 0.0033,
  },
  {
    year: 1966,
    country: "Nigeria",
    "0 - 20": 0.5223,
    "20 - 40": 0.2839,
    "40 - 60": 0.1452,
    "60 - 80": 0.0469,
    "80+": 0.0016,
  },
  {
    year: 1966,
    country: "South Africa",
    "0 - 20": 0.5133,
    "20 - 40": 0.2717,
    "40 - 60": 0.1547,
    "60 - 80": 0.0564,
    "80+": 0.0039,
  },
  {
    year: 1966,
    country: "United Kingdom",
    "0 - 20": 0.3122,
    "20 - 40": 0.2511,
    "40 - 60": 0.2563,
    "60 - 80": 0.1585,
    "80+": 0.0219,
  },
  {
    year: 1966,
    country: "United States",
    "0 - 20": 0.3856,
    "20 - 40": 0.2496,
    "40 - 60": 0.2263,
    "60 - 80": 0.1217,
    "80+": 0.0168,
  },
  {
    year: 1967,
    country: "China",
    "0 - 20": 0.5145,
    "20 - 40": 0.2584,
    "40 - 60": 0.1667,
    "60 - 80": 0.0578,
    "80+": 0.0026,
  },
  {
    year: 1967,
    country: "Germany",
    "0 - 20": 0.2971,
    "20 - 40": 0.2794,
    "40 - 60": 0.2319,
    "60 - 80": 0.1724,
    "80+": 0.0192,
  },
  {
    year: 1967,
    country: "Italy",
    "0 - 20": 0.3246,
    "20 - 40": 0.282,
    "40 - 60": 0.239,
    "60 - 80": 0.1371,
    "80+": 0.0173,
  },
  {
    year: 1967,
    country: "Japan",
    "0 - 20": 0.3527,
    "20 - 40": 0.3446,
    "40 - 60": 0.2039,
    "60 - 80": 0.0904,
    "80+": 0.0084,
  },
  {
    year: 1967,
    country: "Korea, Rep.",
    "0 - 20": 0.5274,
    "20 - 40": 0.2724,
    "40 - 60": 0.1459,
    "60 - 80": 0.0507,
    "80+": 0.0036,
  },
  {
    year: 1967,
    country: "Nigeria",
    "0 - 20": 0.5231,
    "20 - 40": 0.2834,
    "40 - 60": 0.145,
    "60 - 80": 0.0468,
    "80+": 0.0017,
  },
  {
    year: 1967,
    country: "South Africa",
    "0 - 20": 0.5153,
    "20 - 40": 0.2704,
    "40 - 60": 0.1539,
    "60 - 80": 0.0564,
    "80+": 0.004,
  },
  {
    year: 1967,
    country: "United Kingdom",
    "0 - 20": 0.3121,
    "20 - 40": 0.2521,
    "40 - 60": 0.2534,
    "60 - 80": 0.1598,
    "80+": 0.0226,
  },
  {
    year: 1967,
    country: "United States",
    "0 - 20": 0.3832,
    "20 - 40": 0.2514,
    "40 - 60": 0.2254,
    "60 - 80": 0.1226,
    "80+": 0.0175,
  },
  {
    year: 1968,
    country: "China",
    "0 - 20": 0.5168,
    "20 - 40": 0.257,
    "40 - 60": 0.1653,
    "60 - 80": 0.0583,
    "80+": 0.0027,
  },
  {
    year: 1968,
    country: "Germany",
    "0 - 20": 0.2991,
    "20 - 40": 0.2782,
    "40 - 60": 0.2289,
    "60 - 80": 0.1742,
    "80+": 0.0196,
  },
  {
    year: 1968,
    country: "Italy",
    "0 - 20": 0.3225,
    "20 - 40": 0.2814,
    "40 - 60": 0.2387,
    "60 - 80": 0.1395,
    "80+": 0.0178,
  },
  {
    year: 1968,
    country: "Japan",
    "0 - 20": 0.3439,
    "20 - 40": 0.3478,
    "40 - 60": 0.2077,
    "60 - 80": 0.092,
    "80+": 0.0087,
  },
  {
    year: 1968,
    country: "Korea, Rep.",
    "0 - 20": 0.5257,
    "20 - 40": 0.2742,
    "40 - 60": 0.1452,
    "60 - 80": 0.0511,
    "80+": 0.0038,
  },
  {
    year: 1968,
    country: "Nigeria",
    "0 - 20": 0.5237,
    "20 - 40": 0.283,
    "40 - 60": 0.1449,
    "60 - 80": 0.0467,
    "80+": 0.0017,
  },
  {
    year: 1968,
    country: "South Africa",
    "0 - 20": 0.5167,
    "20 - 40": 0.2694,
    "40 - 60": 0.1533,
    "60 - 80": 0.0565,
    "80+": 0.0041,
  },
  {
    year: 1968,
    country: "United Kingdom",
    "0 - 20": 0.3112,
    "20 - 40": 0.254,
    "40 - 60": 0.2505,
    "60 - 80": 0.1614,
    "80+": 0.0229,
  },
  {
    year: 1968,
    country: "United States",
    "0 - 20": 0.3804,
    "20 - 40": 0.2537,
    "40 - 60": 0.2246,
    "60 - 80": 0.1235,
    "80+": 0.0179,
  },
  {
    year: 1969,
    country: "China",
    "0 - 20": 0.5175,
    "20 - 40": 0.2569,
    "40 - 60": 0.1641,
    "60 - 80": 0.0587,
    "80+": 0.0028,
  },
  {
    year: 1969,
    country: "Germany",
    "0 - 20": 0.3005,
    "20 - 40": 0.2773,
    "40 - 60": 0.2264,
    "60 - 80": 0.1763,
    "80+": 0.0196,
  },
  {
    year: 1969,
    country: "Italy",
    "0 - 20": 0.3207,
    "20 - 40": 0.2807,
    "40 - 60": 0.2383,
    "60 - 80": 0.1424,
    "80+": 0.018,
  },
  {
    year: 1969,
    country: "Japan",
    "0 - 20": 0.3361,
    "20 - 40": 0.3499,
    "40 - 60": 0.2115,
    "60 - 80": 0.0938,
    "80+": 0.0087,
  },
  {
    year: 1969,
    country: "Korea, Rep.",
    "0 - 20": 0.5235,
    "20 - 40": 0.276,
    "40 - 60": 0.1449,
    "60 - 80": 0.0516,
    "80+": 0.0039,
  },
  {
    year: 1969,
    country: "Nigeria",
    "0 - 20": 0.5242,
    "20 - 40": 0.2826,
    "40 - 60": 0.145,
    "60 - 80": 0.0466,
    "80+": 0.0017,
  },
  {
    year: 1969,
    country: "South Africa",
    "0 - 20": 0.5175,
    "20 - 40": 0.2689,
    "40 - 60": 0.1529,
    "60 - 80": 0.0568,
    "80+": 0.004,
  },
  {
    year: 1969,
    country: "United Kingdom",
    "0 - 20": 0.3104,
    "20 - 40": 0.256,
    "40 - 60": 0.2475,
    "60 - 80": 0.1632,
    "80+": 0.023,
  },
  {
    year: 1969,
    country: "United States",
    "0 - 20": 0.3773,
    "20 - 40": 0.2565,
    "40 - 60": 0.2237,
    "60 - 80": 0.1244,
    "80+": 0.0182,
  },
  {
    year: 1970,
    country: "China",
    "0 - 20": 0.5166,
    "20 - 40": 0.2582,
    "40 - 60": 0.163,
    "60 - 80": 0.0593,
    "80+": 0.0028,
  },
  {
    year: 1970,
    country: "Germany",
    "0 - 20": 0.3009,
    "20 - 40": 0.277,
    "40 - 60": 0.2242,
    "60 - 80": 0.1786,
    "80+": 0.0193,
  },
  {
    year: 1970,
    country: "Italy",
    "0 - 20": 0.3197,
    "20 - 40": 0.2792,
    "40 - 60": 0.2376,
    "60 - 80": 0.1456,
    "80+": 0.0179,
  },
  {
    year: 1970,
    country: "Japan",
    "0 - 20": 0.3301,
    "20 - 40": 0.3504,
    "40 - 60": 0.215,
    "60 - 80": 0.0958,
    "80+": 0.0087,
  },
  {
    year: 1970,
    country: "Korea, Rep.",
    "0 - 20": 0.5209,
    "20 - 40": 0.2777,
    "40 - 60": 0.1453,
    "60 - 80": 0.0522,
    "80+": 0.0039,
  },
  {
    year: 1970,
    country: "Nigeria",
    "0 - 20": 0.5249,
    "20 - 40": 0.2821,
    "40 - 60": 0.145,
    "60 - 80": 0.0465,
    "80+": 0.0015,
  },
  {
    year: 1970,
    country: "South Africa",
    "0 - 20": 0.5176,
    "20 - 40": 0.2692,
    "40 - 60": 0.1525,
    "60 - 80": 0.057,
    "80+": 0.0037,
  },
  {
    year: 1970,
    country: "United Kingdom",
    "0 - 20": 0.3099,
    "20 - 40": 0.2575,
    "40 - 60": 0.2447,
    "60 - 80": 0.1653,
    "80+": 0.0226,
  },
  {
    year: 1970,
    country: "United States",
    "0 - 20": 0.3739,
    "20 - 40": 0.2598,
    "40 - 60": 0.2226,
    "60 - 80": 0.1254,
    "80+": 0.0183,
  },
  {
    year: 1971,
    country: "China",
    "0 - 20": 0.515,
    "20 - 40": 0.2604,
    "40 - 60": 0.1617,
    "60 - 80": 0.0598,
    "80+": 0.0031,
  },
  {
    year: 1971,
    country: "Germany",
    "0 - 20": 0.3009,
    "20 - 40": 0.277,
    "40 - 60": 0.2218,
    "60 - 80": 0.1799,
    "80+": 0.0204,
  },
  {
    year: 1971,
    country: "Italy",
    "0 - 20": 0.3185,
    "20 - 40": 0.2785,
    "40 - 60": 0.2366,
    "60 - 80": 0.1478,
    "80+": 0.0187,
  },
  {
    year: 1971,
    country: "Japan",
    "0 - 20": 0.325,
    "20 - 40": 0.35,
    "40 - 60": 0.2184,
    "60 - 80": 0.0975,
    "80+": 0.0092,
  },
  {
    year: 1971,
    country: "Korea, Rep.",
    "0 - 20": 0.5185,
    "20 - 40": 0.2788,
    "40 - 60": 0.1458,
    "60 - 80": 0.0526,
    "80+": 0.0042,
  },
  {
    year: 1971,
    country: "Nigeria",
    "0 - 20": 0.5269,
    "20 - 40": 0.2806,
    "40 - 60": 0.1444,
    "60 - 80": 0.0464,
    "80+": 0.0017,
  },
  {
    year: 1971,
    country: "South Africa",
    "0 - 20": 0.518,
    "20 - 40": 0.2695,
    "40 - 60": 0.1516,
    "60 - 80": 0.0569,
    "80+": 0.0039,
  },
  {
    year: 1971,
    country: "United Kingdom",
    "0 - 20": 0.3093,
    "20 - 40": 0.2588,
    "40 - 60": 0.2416,
    "60 - 80": 0.1667,
    "80+": 0.0235,
  },
  {
    year: 1971,
    country: "United States",
    "0 - 20": 0.369,
    "20 - 40": 0.2649,
    "40 - 60": 0.2207,
    "60 - 80": 0.1262,
    "80+": 0.0192,
  },
  {
    year: 1972,
    country: "China",
    "0 - 20": 0.5107,
    "20 - 40": 0.2645,
    "40 - 60": 0.161,
    "60 - 80": 0.0605,
    "80+": 0.0033,
  },
  {
    year: 1972,
    country: "Germany",
    "0 - 20": 0.2995,
    "20 - 40": 0.2782,
    "40 - 60": 0.2196,
    "60 - 80": 0.1816,
    "80+": 0.0211,
  },
  {
    year: 1972,
    country: "Italy",
    "0 - 20": 0.318,
    "20 - 40": 0.2774,
    "40 - 60": 0.2351,
    "60 - 80": 0.1503,
    "80+": 0.0192,
  },
  {
    year: 1972,
    country: "Japan",
    "0 - 20": 0.3216,
    "20 - 40": 0.3481,
    "40 - 60": 0.2214,
    "60 - 80": 0.0993,
    "80+": 0.0095,
  },
  {
    year: 1972,
    country: "Korea, Rep.",
    "0 - 20": 0.5159,
    "20 - 40": 0.2797,
    "40 - 60": 0.1468,
    "60 - 80": 0.0531,
    "80+": 0.0045,
  },
  {
    year: 1972,
    country: "Nigeria",
    "0 - 20": 0.529,
    "20 - 40": 0.2791,
    "40 - 60": 0.1438,
    "60 - 80": 0.0462,
    "80+": 0.0018,
  },
  {
    year: 1972,
    country: "South Africa",
    "0 - 20": 0.5177,
    "20 - 40": 0.2705,
    "40 - 60": 0.1509,
    "60 - 80": 0.0569,
    "80+": 0.004,
  },
  {
    year: 1972,
    country: "United Kingdom",
    "0 - 20": 0.3092,
    "20 - 40": 0.2597,
    "40 - 60": 0.2387,
    "60 - 80": 0.1682,
    "80+": 0.0241,
  },
  {
    year: 1972,
    country: "United States",
    "0 - 20": 0.364,
    "20 - 40": 0.2705,
    "40 - 60": 0.2186,
    "60 - 80": 0.1269,
    "80+": 0.0199,
  },
  {
    year: 1973,
    country: "China",
    "0 - 20": 0.5051,
    "20 - 40": 0.2695,
    "40 - 60": 0.1606,
    "60 - 80": 0.0614,
    "80+": 0.0035,
  },
  {
    year: 1973,
    country: "Germany",
    "0 - 20": 0.2971,
    "20 - 40": 0.2799,
    "40 - 60": 0.2183,
    "60 - 80": 0.1832,
    "80+": 0.0216,
  },
  {
    year: 1973,
    country: "Italy",
    "0 - 20": 0.3178,
    "20 - 40": 0.2761,
    "40 - 60": 0.2337,
    "60 - 80": 0.1529,
    "80+": 0.0195,
  },
  {
    year: 1973,
    country: "Japan",
    "0 - 20": 0.3195,
    "20 - 40": 0.3452,
    "40 - 60": 0.2243,
    "60 - 80": 0.1012,
    "80+": 0.0098,
  },
  {
    year: 1973,
    country: "Korea, Rep.",
    "0 - 20": 0.5128,
    "20 - 40": 0.2808,
    "40 - 60": 0.1482,
    "60 - 80": 0.0536,
    "80+": 0.0046,
  },
  {
    year: 1973,
    country: "Nigeria",
    "0 - 20": 0.531,
    "20 - 40": 0.2777,
    "40 - 60": 0.1434,
    "60 - 80": 0.0461,
    "80+": 0.0019,
  },
  {
    year: 1973,
    country: "South Africa",
    "0 - 20": 0.5169,
    "20 - 40": 0.2719,
    "40 - 60": 0.1504,
    "60 - 80": 0.0569,
    "80+": 0.004,
  },
  {
    year: 1973,
    country: "United Kingdom",
    "0 - 20": 0.3092,
    "20 - 40": 0.2606,
    "40 - 60": 0.236,
    "60 - 80": 0.1698,
    "80+": 0.0245,
  },
  {
    year: 1973,
    country: "United States",
    "0 - 20": 0.3588,
    "20 - 40": 0.2765,
    "40 - 60": 0.2165,
    "60 - 80": 0.1277,
    "80+": 0.0205,
  },
  {
    year: 1974,
    country: "China",
    "0 - 20": 0.4994,
    "20 - 40": 0.2742,
    "40 - 60": 0.1604,
    "60 - 80": 0.0624,
    "80+": 0.0036,
  },
  {
    year: 1974,
    country: "Germany",
    "0 - 20": 0.2943,
    "20 - 40": 0.2812,
    "40 - 60": 0.2189,
    "60 - 80": 0.1839,
    "80+": 0.0217,
  },
  {
    year: 1974,
    country: "Italy",
    "0 - 20": 0.3172,
    "20 - 40": 0.2748,
    "40 - 60": 0.2336,
    "60 - 80": 0.1548,
    "80+": 0.0197,
  },
  {
    year: 1974,
    country: "Japan",
    "0 - 20": 0.3177,
    "20 - 40": 0.3418,
    "40 - 60": 0.2274,
    "60 - 80": 0.1031,
    "80+": 0.01,
  },
  {
    year: 1974,
    country: "Korea, Rep.",
    "0 - 20": 0.5083,
    "20 - 40": 0.2828,
    "40 - 60": 0.1501,
    "60 - 80": 0.0541,
    "80+": 0.0047,
  },
  {
    year: 1974,
    country: "Nigeria",
    "0 - 20": 0.5327,
    "20 - 40": 0.2764,
    "40 - 60": 0.1431,
    "60 - 80": 0.046,
    "80+": 0.0018,
  },
  {
    year: 1974,
    country: "South Africa",
    "0 - 20": 0.5158,
    "20 - 40": 0.2735,
    "40 - 60": 0.1499,
    "60 - 80": 0.057,
    "80+": 0.0038,
  },
  {
    year: 1974,
    country: "United Kingdom",
    "0 - 20": 0.3087,
    "20 - 40": 0.2617,
    "40 - 60": 0.2338,
    "60 - 80": 0.1713,
    "80+": 0.0245,
  },
  {
    year: 1974,
    country: "United States",
    "0 - 20": 0.353,
    "20 - 40": 0.283,
    "40 - 60": 0.2146,
    "60 - 80": 0.1285,
    "80+": 0.021,
  },
  {
    year: 1975,
    country: "China",
    "0 - 20": 0.4943,
    "20 - 40": 0.2782,
    "40 - 60": 0.1603,
    "60 - 80": 0.0636,
    "80+": 0.0035,
  },
  {
    year: 1975,
    country: "Germany",
    "0 - 20": 0.2914,
    "20 - 40": 0.2817,
    "40 - 60": 0.2219,
    "60 - 80": 0.1834,
    "80+": 0.0216,
  },
  {
    year: 1975,
    country: "Italy",
    "0 - 20": 0.3158,
    "20 - 40": 0.2737,
    "40 - 60": 0.2351,
    "60 - 80": 0.1558,
    "80+": 0.0196,
  },
  {
    year: 1975,
    country: "Japan",
    "0 - 20": 0.3156,
    "20 - 40": 0.3384,
    "40 - 60": 0.2308,
    "60 - 80": 0.1051,
    "80+": 0.0101,
  },
  {
    year: 1975,
    country: "Korea, Rep.",
    "0 - 20": 0.502,
    "20 - 40": 0.2861,
    "40 - 60": 0.1524,
    "60 - 80": 0.0547,
    "80+": 0.0047,
  },
  {
    year: 1975,
    country: "Nigeria",
    "0 - 20": 0.5342,
    "20 - 40": 0.2753,
    "40 - 60": 0.1428,
    "60 - 80": 0.046,
    "80+": 0.0017,
  },
  {
    year: 1975,
    country: "South Africa",
    "0 - 20": 0.5144,
    "20 - 40": 0.2751,
    "40 - 60": 0.1496,
    "60 - 80": 0.0572,
    "80+": 0.0036,
  },
  {
    year: 1975,
    country: "United Kingdom",
    "0 - 20": 0.3074,
    "20 - 40": 0.2633,
    "40 - 60": 0.2323,
    "60 - 80": 0.1726,
    "80+": 0.0244,
  },
  {
    year: 1975,
    country: "United States",
    "0 - 20": 0.3466,
    "20 - 40": 0.2898,
    "40 - 60": 0.2128,
    "60 - 80": 0.1295,
    "80+": 0.0212,
  },
  {
    year: 1976,
    country: "China",
    "0 - 20": 0.4889,
    "20 - 40": 0.282,
    "40 - 60": 0.1605,
    "60 - 80": 0.0648,
    "80+": 0.0038,
  },
  {
    year: 1976,
    country: "Germany",
    "0 - 20": 0.287,
    "20 - 40": 0.2815,
    "40 - 60": 0.2274,
    "60 - 80": 0.1811,
    "80+": 0.023,
  },
  {
    year: 1976,
    country: "Italy",
    "0 - 20": 0.3143,
    "20 - 40": 0.2729,
    "40 - 60": 0.2374,
    "60 - 80": 0.1548,
    "80+": 0.0205,
  },
  {
    year: 1976,
    country: "Japan",
    "0 - 20": 0.3148,
    "20 - 40": 0.334,
    "40 - 60": 0.2339,
    "60 - 80": 0.1065,
    "80+": 0.0108,
  },
  {
    year: 1976,
    country: "Korea, Rep.",
    "0 - 20": 0.4948,
    "20 - 40": 0.2904,
    "40 - 60": 0.1546,
    "60 - 80": 0.0551,
    "80+": 0.005,
  },
  {
    year: 1976,
    country: "Nigeria",
    "0 - 20": 0.5365,
    "20 - 40": 0.2741,
    "40 - 60": 0.1417,
    "60 - 80": 0.0458,
    "80+": 0.0019,
  },
  {
    year: 1976,
    country: "South Africa",
    "0 - 20": 0.5144,
    "20 - 40": 0.2759,
    "40 - 60": 0.1487,
    "60 - 80": 0.0572,
    "80+": 0.0038,
  },
  {
    year: 1976,
    country: "United Kingdom",
    "0 - 20": 0.3058,
    "20 - 40": 0.2651,
    "40 - 60": 0.2311,
    "60 - 80": 0.1727,
    "80+": 0.0254,
  },
  {
    year: 1976,
    country: "United States",
    "0 - 20": 0.3418,
    "20 - 40": 0.2955,
    "40 - 60": 0.2101,
    "60 - 80": 0.1306,
    "80+": 0.022,
  },
  {
    year: 1977,
    country: "China",
    "0 - 20": 0.4839,
    "20 - 40": 0.285,
    "40 - 60": 0.1608,
    "60 - 80": 0.0663,
    "80+": 0.004,
  },
  {
    year: 1977,
    country: "Germany",
    "0 - 20": 0.2835,
    "20 - 40": 0.2801,
    "40 - 60": 0.2349,
    "60 - 80": 0.1774,
    "80+": 0.0241,
  },
  {
    year: 1977,
    country: "Italy",
    "0 - 20": 0.312,
    "20 - 40": 0.2723,
    "40 - 60": 0.2415,
    "60 - 80": 0.1528,
    "80+": 0.0213,
  },
  {
    year: 1977,
    country: "Japan",
    "0 - 20": 0.3129,
    "20 - 40": 0.33,
    "40 - 60": 0.2375,
    "60 - 80": 0.1082,
    "80+": 0.0114,
  },
  {
    year: 1977,
    country: "Korea, Rep.",
    "0 - 20": 0.4853,
    "20 - 40": 0.2963,
    "40 - 60": 0.1576,
    "60 - 80": 0.0556,
    "80+": 0.0053,
  },
  {
    year: 1977,
    country: "Nigeria",
    "0 - 20": 0.5382,
    "20 - 40": 0.2733,
    "40 - 60": 0.1408,
    "60 - 80": 0.0457,
    "80+": 0.002,
  },
  {
    year: 1977,
    country: "South Africa",
    "0 - 20": 0.5139,
    "20 - 40": 0.277,
    "40 - 60": 0.1479,
    "60 - 80": 0.0572,
    "80+": 0.004,
  },
  {
    year: 1977,
    country: "United Kingdom",
    "0 - 20": 0.3039,
    "20 - 40": 0.2671,
    "40 - 60": 0.2305,
    "60 - 80": 0.1724,
    "80+": 0.0261,
  },
  {
    year: 1977,
    country: "United States",
    "0 - 20": 0.3363,
    "20 - 40": 0.3015,
    "40 - 60": 0.2077,
    "60 - 80": 0.132,
    "80+": 0.0225,
  },
  {
    year: 1978,
    country: "China",
    "0 - 20": 0.4789,
    "20 - 40": 0.2879,
    "40 - 60": 0.1612,
    "60 - 80": 0.0678,
    "80+": 0.0041,
  },
  {
    year: 1978,
    country: "Germany",
    "0 - 20": 0.2801,
    "20 - 40": 0.2784,
    "40 - 60": 0.2435,
    "60 - 80": 0.173,
    "80+": 0.025,
  },
  {
    year: 1978,
    country: "Italy",
    "0 - 20": 0.3089,
    "20 - 40": 0.2721,
    "40 - 60": 0.2464,
    "60 - 80": 0.1508,
    "80+": 0.0218,
  },
  {
    year: 1978,
    country: "Japan",
    "0 - 20": 0.3103,
    "20 - 40": 0.3262,
    "40 - 60": 0.2414,
    "60 - 80": 0.1101,
    "80+": 0.012,
  },
  {
    year: 1978,
    country: "Korea, Rep.",
    "0 - 20": 0.4745,
    "20 - 40": 0.3029,
    "40 - 60": 0.1609,
    "60 - 80": 0.0562,
    "80+": 0.0055,
  },
  {
    year: 1978,
    country: "Nigeria",
    "0 - 20": 0.5393,
    "20 - 40": 0.2729,
    "40 - 60": 0.1402,
    "60 - 80": 0.0456,
    "80+": 0.002,
  },
  {
    year: 1978,
    country: "South Africa",
    "0 - 20": 0.5131,
    "20 - 40": 0.2782,
    "40 - 60": 0.1474,
    "60 - 80": 0.0574,
    "80+": 0.004,
  },
  {
    year: 1978,
    country: "United Kingdom",
    "0 - 20": 0.3014,
    "20 - 40": 0.2695,
    "40 - 60": 0.2304,
    "60 - 80": 0.1721,
    "80+": 0.0266,
  },
  {
    year: 1978,
    country: "United States",
    "0 - 20": 0.3305,
    "20 - 40": 0.3075,
    "40 - 60": 0.2056,
    "60 - 80": 0.1337,
    "80+": 0.0227,
  },
  {
    year: 1979,
    country: "China",
    "0 - 20": 0.4734,
    "20 - 40": 0.2914,
    "40 - 60": 0.1617,
    "60 - 80": 0.0693,
    "80+": 0.0042,
  },
  {
    year: 1979,
    country: "Germany",
    "0 - 20": 0.2758,
    "20 - 40": 0.2777,
    "40 - 60": 0.2515,
    "60 - 80": 0.1695,
    "80+": 0.0255,
  },
  {
    year: 1979,
    country: "Italy",
    "0 - 20": 0.3051,
    "20 - 40": 0.2726,
    "40 - 60": 0.2504,
    "60 - 80": 0.1497,
    "80+": 0.0222,
  },
  {
    year: 1979,
    country: "Japan",
    "0 - 20": 0.3079,
    "20 - 40": 0.3221,
    "40 - 60": 0.2453,
    "60 - 80": 0.1122,
    "80+": 0.0125,
  },
  {
    year: 1979,
    country: "Korea, Rep.",
    "0 - 20": 0.4638,
    "20 - 40": 0.3096,
    "40 - 60": 0.164,
    "60 - 80": 0.0571,
    "80+": 0.0055,
  },
  {
    year: 1979,
    country: "Nigeria",
    "0 - 20": 0.54,
    "20 - 40": 0.2727,
    "40 - 60": 0.1397,
    "60 - 80": 0.0455,
    "80+": 0.002,
  },
  {
    year: 1979,
    country: "South Africa",
    "0 - 20": 0.5123,
    "20 - 40": 0.2794,
    "40 - 60": 0.1469,
    "60 - 80": 0.0576,
    "80+": 0.0039,
  },
  {
    year: 1979,
    country: "United Kingdom",
    "0 - 20": 0.2983,
    "20 - 40": 0.2724,
    "40 - 60": 0.2303,
    "60 - 80": 0.1722,
    "80+": 0.0268,
  },
  {
    year: 1979,
    country: "United States",
    "0 - 20": 0.3247,
    "20 - 40": 0.3133,
    "40 - 60": 0.2038,
    "60 - 80": 0.1356,
    "80+": 0.0227,
  },
  {
    year: 1980,
    country: "China",
    "0 - 20": 0.4672,
    "20 - 40": 0.2958,
    "40 - 60": 0.1622,
    "60 - 80": 0.0707,
    "80+": 0.0042,
  },
  {
    year: 1980,
    country: "Germany",
    "0 - 20": 0.2703,
    "20 - 40": 0.2787,
    "40 - 60": 0.2578,
    "60 - 80": 0.1675,
    "80+": 0.0258,
  },
  {
    year: 1980,
    country: "Italy",
    "0 - 20": 0.3009,
    "20 - 40": 0.2739,
    "40 - 60": 0.2528,
    "60 - 80": 0.1501,
    "80+": 0.0223,
  },
  {
    year: 1980,
    country: "Japan",
    "0 - 20": 0.306,
    "20 - 40": 0.3178,
    "40 - 60": 0.2488,
    "60 - 80": 0.1145,
    "80+": 0.0129,
  },
  {
    year: 1980,
    country: "Korea, Rep.",
    "0 - 20": 0.454,
    "20 - 40": 0.3158,
    "40 - 60": 0.1666,
    "60 - 80": 0.0582,
    "80+": 0.0054,
  },
  {
    year: 1980,
    country: "Nigeria",
    "0 - 20": 0.5405,
    "20 - 40": 0.2726,
    "40 - 60": 0.1395,
    "60 - 80": 0.0456,
    "80+": 0.0018,
  },
  {
    year: 1980,
    country: "South Africa",
    "0 - 20": 0.5117,
    "20 - 40": 0.2803,
    "40 - 60": 0.1465,
    "60 - 80": 0.0578,
    "80+": 0.0037,
  },
  {
    year: 1980,
    country: "United Kingdom",
    "0 - 20": 0.2945,
    "20 - 40": 0.2759,
    "40 - 60": 0.2298,
    "60 - 80": 0.1729,
    "80+": 0.0269,
  },
  {
    year: 1980,
    country: "United States",
    "0 - 20": 0.3191,
    "20 - 40": 0.3188,
    "40 - 60": 0.202,
    "60 - 80": 0.1375,
    "80+": 0.0227,
  },
  {
    year: 1981,
    country: "China",
    "0 - 20": 0.4596,
    "20 - 40": 0.3015,
    "40 - 60": 0.1626,
    "60 - 80": 0.0717,
    "80+": 0.0046,
  },
  {
    year: 1981,
    country: "Germany",
    "0 - 20": 0.2655,
    "20 - 40": 0.28,
    "40 - 60": 0.2615,
    "60 - 80": 0.1653,
    "80+": 0.0276,
  },
  {
    year: 1981,
    country: "Italy",
    "0 - 20": 0.2958,
    "20 - 40": 0.2767,
    "40 - 60": 0.2533,
    "60 - 80": 0.1508,
    "80+": 0.0235,
  },
  {
    year: 1981,
    country: "Japan",
    "0 - 20": 0.3021,
    "20 - 40": 0.3145,
    "40 - 60": 0.2526,
    "60 - 80": 0.1168,
    "80+": 0.014,
  },
  {
    year: 1981,
    country: "Korea, Rep.",
    "0 - 20": 0.4435,
    "20 - 40": 0.3229,
    "40 - 60": 0.1685,
    "60 - 80": 0.0595,
    "80+": 0.0056,
  },
  {
    year: 1981,
    country: "Nigeria",
    "0 - 20": 0.543,
    "20 - 40": 0.2706,
    "40 - 60": 0.1388,
    "60 - 80": 0.0457,
    "80+": 0.002,
  },
  {
    year: 1981,
    country: "South Africa",
    "0 - 20": 0.5111,
    "20 - 40": 0.2815,
    "40 - 60": 0.1461,
    "60 - 80": 0.0576,
    "80+": 0.0038,
  },
  {
    year: 1981,
    country: "United Kingdom",
    "0 - 20": 0.2906,
    "20 - 40": 0.2796,
    "40 - 60": 0.2285,
    "60 - 80": 0.173,
    "80+": 0.0284,
  },
  {
    year: 1981,
    country: "United States",
    "0 - 20": 0.3135,
    "20 - 40": 0.3242,
    "40 - 60": 0.2005,
    "60 - 80": 0.138,
    "80+": 0.0237,
  },
  {
    year: 1982,
    country: "China",
    "0 - 20": 0.4524,
    "20 - 40": 0.3072,
    "40 - 60": 0.1629,
    "60 - 80": 0.0725,
    "80+": 0.005,
  },
  {
    year: 1982,
    country: "Germany",
    "0 - 20": 0.2594,
    "20 - 40": 0.2829,
    "40 - 60": 0.2639,
    "60 - 80": 0.1647,
    "80+": 0.0291,
  },
  {
    year: 1982,
    country: "Italy",
    "0 - 20": 0.2904,
    "20 - 40": 0.2804,
    "40 - 60": 0.2522,
    "60 - 80": 0.1527,
    "80+": 0.0244,
  },
  {
    year: 1982,
    country: "Japan",
    "0 - 20": 0.2994,
    "20 - 40": 0.3108,
    "40 - 60": 0.2558,
    "60 - 80": 0.119,
    "80+": 0.0149,
  },
  {
    year: 1982,
    country: "Korea, Rep.",
    "0 - 20": 0.4345,
    "20 - 40": 0.3291,
    "40 - 60": 0.1698,
    "60 - 80": 0.0608,
    "80+": 0.0057,
  },
  {
    year: 1982,
    country: "Nigeria",
    "0 - 20": 0.545,
    "20 - 40": 0.2689,
    "40 - 60": 0.1383,
    "60 - 80": 0.0457,
    "80+": 0.0021,
  },
  {
    year: 1982,
    country: "South Africa",
    "0 - 20": 0.5108,
    "20 - 40": 0.2822,
    "40 - 60": 0.1457,
    "60 - 80": 0.0573,
    "80+": 0.0039,
  },
  {
    year: 1982,
    country: "United Kingdom",
    "0 - 20": 0.2863,
    "20 - 40": 0.2837,
    "40 - 60": 0.227,
    "60 - 80": 0.1735,
    "80+": 0.0296,
  },
  {
    year: 1982,
    country: "United States",
    "0 - 20": 0.3083,
    "20 - 40": 0.3292,
    "40 - 60": 0.1994,
    "60 - 80": 0.1386,
    "80+": 0.0246,
  },
  {
    year: 1983,
    country: "China",
    "0 - 20": 0.4451,
    "20 - 40": 0.3132,
    "40 - 60": 0.1632,
    "60 - 80": 0.0733,
    "80+": 0.0052,
  },
  {
    year: 1983,
    country: "Germany",
    "0 - 20": 0.2524,
    "20 - 40": 0.2869,
    "40 - 60": 0.2652,
    "60 - 80": 0.1653,
    "80+": 0.0303,
  },
  {
    year: 1983,
    country: "Italy",
    "0 - 20": 0.2846,
    "20 - 40": 0.2845,
    "40 - 60": 0.2503,
    "60 - 80": 0.1556,
    "80+": 0.025,
  },
  {
    year: 1983,
    country: "Japan",
    "0 - 20": 0.2972,
    "20 - 40": 0.3067,
    "40 - 60": 0.2588,
    "60 - 80": 0.1216,
    "80+": 0.0157,
  },
  {
    year: 1983,
    country: "Korea, Rep.",
    "0 - 20": 0.4265,
    "20 - 40": 0.3347,
    "40 - 60": 0.1708,
    "60 - 80": 0.0622,
    "80+": 0.0058,
  },
  {
    year: 1983,
    country: "Nigeria",
    "0 - 20": 0.5468,
    "20 - 40": 0.2674,
    "40 - 60": 0.1379,
    "60 - 80": 0.0458,
    "80+": 0.0021,
  },
  {
    year: 1983,
    country: "South Africa",
    "0 - 20": 0.5107,
    "20 - 40": 0.2829,
    "40 - 60": 0.1454,
    "60 - 80": 0.0571,
    "80+": 0.0039,
  },
  {
    year: 1983,
    country: "United Kingdom",
    "0 - 20": 0.2817,
    "20 - 40": 0.2879,
    "40 - 60": 0.2255,
    "60 - 80": 0.1743,
    "80+": 0.0305,
  },
  {
    year: 1983,
    country: "United States",
    "0 - 20": 0.3034,
    "20 - 40": 0.3333,
    "40 - 60": 0.1987,
    "60 - 80": 0.1392,
    "80+": 0.0254,
  },
  {
    year: 1984,
    country: "China",
    "0 - 20": 0.4374,
    "20 - 40": 0.3195,
    "40 - 60": 0.1636,
    "60 - 80": 0.0742,
    "80+": 0.0053,
  },
  {
    year: 1984,
    country: "Germany",
    "0 - 20": 0.2455,
    "20 - 40": 0.2914,
    "40 - 60": 0.2658,
    "60 - 80": 0.1662,
    "80+": 0.0311,
  },
  {
    year: 1984,
    country: "Italy",
    "0 - 20": 0.2784,
    "20 - 40": 0.2884,
    "40 - 60": 0.2489,
    "60 - 80": 0.1587,
    "80+": 0.0255,
  },
  {
    year: 1984,
    country: "Japan",
    "0 - 20": 0.2943,
    "20 - 40": 0.3025,
    "40 - 60": 0.2622,
    "60 - 80": 0.1246,
    "80+": 0.0165,
  },
  {
    year: 1984,
    country: "Korea, Rep.",
    "0 - 20": 0.418,
    "20 - 40": 0.3402,
    "40 - 60": 0.1724,
    "60 - 80": 0.0636,
    "80+": 0.0058,
  },
  {
    year: 1984,
    country: "Nigeria",
    "0 - 20": 0.5483,
    "20 - 40": 0.2661,
    "40 - 60": 0.1377,
    "60 - 80": 0.046,
    "80+": 0.002,
  },
  {
    year: 1984,
    country: "South Africa",
    "0 - 20": 0.5103,
    "20 - 40": 0.2838,
    "40 - 60": 0.1451,
    "60 - 80": 0.057,
    "80+": 0.0038,
  },
  {
    year: 1984,
    country: "United Kingdom",
    "0 - 20": 0.2771,
    "20 - 40": 0.2917,
    "40 - 60": 0.2249,
    "60 - 80": 0.1752,
    "80+": 0.0311,
  },
  {
    year: 1984,
    country: "United States",
    "0 - 20": 0.2992,
    "20 - 40": 0.3363,
    "40 - 60": 0.1988,
    "60 - 80": 0.1398,
    "80+": 0.026,
  },
  {
    year: 1985,
    country: "China",
    "0 - 20": 0.4292,
    "20 - 40": 0.3261,
    "40 - 60": 0.1642,
    "60 - 80": 0.0752,
    "80+": 0.0054,
  },
  {
    year: 1985,
    country: "Germany",
    "0 - 20": 0.2392,
    "20 - 40": 0.2959,
    "40 - 60": 0.2661,
    "60 - 80": 0.167,
    "80+": 0.0317,
  },
  {
    year: 1985,
    country: "Italy",
    "0 - 20": 0.272,
    "20 - 40": 0.2918,
    "40 - 60": 0.2486,
    "60 - 80": 0.1617,
    "80+": 0.0259,
  },
  {
    year: 1985,
    country: "Japan",
    "0 - 20": 0.2904,
    "20 - 40": 0.2981,
    "40 - 60": 0.2661,
    "60 - 80": 0.1282,
    "80+": 0.0172,
  },
  {
    year: 1985,
    country: "Korea, Rep.",
    "0 - 20": 0.4084,
    "20 - 40": 0.3459,
    "40 - 60": 0.1748,
    "60 - 80": 0.065,
    "80+": 0.0058,
  },
  {
    year: 1985,
    country: "Nigeria",
    "0 - 20": 0.5495,
    "20 - 40": 0.265,
    "40 - 60": 0.1375,
    "60 - 80": 0.0462,
    "80+": 0.0019,
  },
  {
    year: 1985,
    country: "South Africa",
    "0 - 20": 0.5094,
    "20 - 40": 0.2851,
    "40 - 60": 0.1449,
    "60 - 80": 0.057,
    "80+": 0.0036,
  },
  {
    year: 1985,
    country: "United Kingdom",
    "0 - 20": 0.2726,
    "20 - 40": 0.2947,
    "40 - 60": 0.2254,
    "60 - 80": 0.1759,
    "80+": 0.0315,
  },
  {
    year: 1985,
    country: "United States",
    "0 - 20": 0.2955,
    "20 - 40": 0.3381,
    "40 - 60": 0.1996,
    "60 - 80": 0.1405,
    "80+": 0.0263,
  },
  {
    year: 1986,
    country: "China",
    "0 - 20": 0.4223,
    "20 - 40": 0.332,
    "40 - 60": 0.1644,
    "60 - 80": 0.0756,
    "80+": 0.0057,
  },
  {
    year: 1986,
    country: "Germany",
    "0 - 20": 0.2336,
    "20 - 40": 0.3009,
    "40 - 60": 0.2654,
    "60 - 80": 0.1665,
    "80+": 0.0336,
  },
  {
    year: 1986,
    country: "Italy",
    "0 - 20": 0.2659,
    "20 - 40": 0.2941,
    "40 - 60": 0.2485,
    "60 - 80": 0.1639,
    "80+": 0.0276,
  },
  {
    year: 1986,
    country: "Japan",
    "0 - 20": 0.287,
    "20 - 40": 0.2929,
    "40 - 60": 0.27,
    "60 - 80": 0.1317,
    "80+": 0.0184,
  },
  {
    year: 1986,
    country: "Korea, Rep.",
    "0 - 20": 0.4001,
    "20 - 40": 0.3504,
    "40 - 60": 0.1772,
    "60 - 80": 0.0661,
    "80+": 0.0062,
  },
  {
    year: 1986,
    country: "Nigeria",
    "0 - 20": 0.5513,
    "20 - 40": 0.2639,
    "40 - 60": 0.1367,
    "60 - 80": 0.046,
    "80+": 0.002,
  },
  {
    year: 1986,
    country: "South Africa",
    "0 - 20": 0.5098,
    "20 - 40": 0.2855,
    "40 - 60": 0.1441,
    "60 - 80": 0.0569,
    "80+": 0.0039,
  },
  {
    year: 1986,
    country: "United Kingdom",
    "0 - 20": 0.2691,
    "20 - 40": 0.2964,
    "40 - 60": 0.2263,
    "60 - 80": 0.1752,
    "80+": 0.033,
  },
  {
    year: 1986,
    country: "United States",
    "0 - 20": 0.2931,
    "20 - 40": 0.3385,
    "40 - 60": 0.2008,
    "60 - 80": 0.1403,
    "80+": 0.0273,
  },
  {
    year: 1987,
    country: "China",
    "0 - 20": 0.4153,
    "20 - 40": 0.3379,
    "40 - 60": 0.1647,
    "60 - 80": 0.076,
    "80+": 0.006,
  },
  {
    year: 1987,
    country: "Germany",
    "0 - 20": 0.2281,
    "20 - 40": 0.3061,
    "40 - 60": 0.2644,
    "60 - 80": 0.1662,
    "80+": 0.0351,
  },
  {
    year: 1987,
    country: "Italy",
    "0 - 20": 0.2596,
    "20 - 40": 0.2958,
    "40 - 60": 0.2495,
    "60 - 80": 0.1661,
    "80+": 0.0291,
  },
  {
    year: 1987,
    country: "Japan",
    "0 - 20": 0.2827,
    "20 - 40": 0.2875,
    "40 - 60": 0.2746,
    "60 - 80": 0.1358,
    "80+": 0.0196,
  },
  {
    year: 1987,
    country: "Korea, Rep.",
    "0 - 20": 0.3898,
    "20 - 40": 0.3557,
    "40 - 60": 0.1807,
    "60 - 80": 0.0673,
    "80+": 0.0065,
  },
  {
    year: 1987,
    country: "Nigeria",
    "0 - 20": 0.5526,
    "20 - 40": 0.2631,
    "40 - 60": 0.1362,
    "60 - 80": 0.0459,
    "80+": 0.0021,
  },
  {
    year: 1987,
    country: "South Africa",
    "0 - 20": 0.5092,
    "20 - 40": 0.2864,
    "40 - 60": 0.1434,
    "60 - 80": 0.0569,
    "80+": 0.0041,
  },
  {
    year: 1987,
    country: "United Kingdom",
    "0 - 20": 0.2655,
    "20 - 40": 0.2973,
    "40 - 60": 0.2285,
    "60 - 80": 0.1744,
    "80+": 0.0342,
  },
  {
    year: 1987,
    country: "United States",
    "0 - 20": 0.2913,
    "20 - 40": 0.3377,
    "40 - 60": 0.2028,
    "60 - 80": 0.1402,
    "80+": 0.028,
  },
  {
    year: 1988,
    country: "China",
    "0 - 20": 0.4082,
    "20 - 40": 0.3435,
    "40 - 60": 0.1655,
    "60 - 80": 0.0765,
    "80+": 0.0062,
  },
  {
    year: 1988,
    country: "Germany",
    "0 - 20": 0.2232,
    "20 - 40": 0.311,
    "40 - 60": 0.2634,
    "60 - 80": 0.1662,
    "80+": 0.0362,
  },
  {
    year: 1988,
    country: "Italy",
    "0 - 20": 0.2532,
    "20 - 40": 0.2971,
    "40 - 60": 0.2511,
    "60 - 80": 0.1682,
    "80+": 0.0304,
  },
  {
    year: 1988,
    country: "Japan",
    "0 - 20": 0.2775,
    "20 - 40": 0.2822,
    "40 - 60": 0.2793,
    "60 - 80": 0.1403,
    "80+": 0.0207,
  },
  {
    year: 1988,
    country: "Korea, Rep.",
    "0 - 20": 0.3785,
    "20 - 40": 0.3611,
    "40 - 60": 0.1849,
    "60 - 80": 0.0686,
    "80+": 0.0068,
  },
  {
    year: 1988,
    country: "Nigeria",
    "0 - 20": 0.5534,
    "20 - 40": 0.2627,
    "40 - 60": 0.1358,
    "60 - 80": 0.0459,
    "80+": 0.0022,
  },
  {
    year: 1988,
    country: "South Africa",
    "0 - 20": 0.5081,
    "20 - 40": 0.2878,
    "40 - 60": 0.143,
    "60 - 80": 0.057,
    "80+": 0.0042,
  },
  {
    year: 1988,
    country: "United Kingdom",
    "0 - 20": 0.2621,
    "20 - 40": 0.2976,
    "40 - 60": 0.2314,
    "60 - 80": 0.1737,
    "80+": 0.0352,
  },
  {
    year: 1988,
    country: "United States",
    "0 - 20": 0.2898,
    "20 - 40": 0.336,
    "40 - 60": 0.2056,
    "60 - 80": 0.1402,
    "80+": 0.0285,
  },
  {
    year: 1989,
    country: "China",
    "0 - 20": 0.4007,
    "20 - 40": 0.3483,
    "40 - 60": 0.1673,
    "60 - 80": 0.0774,
    "80+": 0.0063,
  },
  {
    year: 1989,
    country: "Germany",
    "0 - 20": 0.2192,
    "20 - 40": 0.3148,
    "40 - 60": 0.2628,
    "60 - 80": 0.1663,
    "80+": 0.037,
  },
  {
    year: 1989,
    country: "Italy",
    "0 - 20": 0.2468,
    "20 - 40": 0.2984,
    "40 - 60": 0.2528,
    "60 - 80": 0.1703,
    "80+": 0.0317,
  },
  {
    year: 1989,
    country: "Japan",
    "0 - 20": 0.2718,
    "20 - 40": 0.2778,
    "40 - 60": 0.2835,
    "60 - 80": 0.1451,
    "80+": 0.0217,
  },
  {
    year: 1989,
    country: "Korea, Rep.",
    "0 - 20": 0.3676,
    "20 - 40": 0.3661,
    "40 - 60": 0.189,
    "60 - 80": 0.0702,
    "80+": 0.007,
  },
  {
    year: 1989,
    country: "Nigeria",
    "0 - 20": 0.5537,
    "20 - 40": 0.2628,
    "40 - 60": 0.1355,
    "60 - 80": 0.0459,
    "80+": 0.0021,
  },
  {
    year: 1989,
    country: "South Africa",
    "0 - 20": 0.5065,
    "20 - 40": 0.2891,
    "40 - 60": 0.1429,
    "60 - 80": 0.0573,
    "80+": 0.0041,
  },
  {
    year: 1989,
    country: "United Kingdom",
    "0 - 20": 0.2594,
    "20 - 40": 0.2975,
    "40 - 60": 0.2343,
    "60 - 80": 0.173,
    "80+": 0.0358,
  },
  {
    year: 1989,
    country: "United States",
    "0 - 20": 0.2888,
    "20 - 40": 0.3334,
    "40 - 60": 0.2091,
    "60 - 80": 0.14,
    "80+": 0.0288,
  },
  {
    year: 1990,
    country: "China",
    "0 - 20": 0.3922,
    "20 - 40": 0.3523,
    "40 - 60": 0.1705,
    "60 - 80": 0.0786,
    "80+": 0.0064,
  },
  {
    year: 1990,
    country: "Germany",
    "0 - 20": 0.2162,
    "20 - 40": 0.317,
    "40 - 60": 0.2629,
    "60 - 80": 0.1666,
    "80+": 0.0374,
  },
  {
    year: 1990,
    country: "Italy",
    "0 - 20": 0.2404,
    "20 - 40": 0.3001,
    "40 - 60": 0.2541,
    "60 - 80": 0.1724,
    "80+": 0.033,
  },
  {
    year: 1990,
    country: "Japan",
    "0 - 20": 0.2658,
    "20 - 40": 0.2746,
    "40 - 60": 0.2868,
    "60 - 80": 0.15,
    "80+": 0.0228,
  },
  {
    year: 1990,
    country: "Korea, Rep.",
    "0 - 20": 0.3581,
    "20 - 40": 0.3702,
    "40 - 60": 0.1925,
    "60 - 80": 0.072,
    "80+": 0.0071,
  },
  {
    year: 1990,
    country: "Nigeria",
    "0 - 20": 0.5534,
    "20 - 40": 0.2634,
    "40 - 60": 0.1353,
    "60 - 80": 0.046,
    "80+": 0.0019,
  },
  {
    year: 1990,
    country: "South Africa",
    "0 - 20": 0.5044,
    "20 - 40": 0.2905,
    "40 - 60": 0.1433,
    "60 - 80": 0.0577,
    "80+": 0.004,
  },
  {
    year: 1990,
    country: "United Kingdom",
    "0 - 20": 0.2574,
    "20 - 40": 0.2975,
    "40 - 60": 0.2366,
    "60 - 80": 0.1724,
    "80+": 0.0361,
  },
  {
    year: 1990,
    country: "United States",
    "0 - 20": 0.2879,
    "20 - 40": 0.3304,
    "40 - 60": 0.2131,
    "60 - 80": 0.1397,
    "80+": 0.029,
  },
  {
    year: 1991,
    country: "China",
    "0 - 20": 0.3862,
    "20 - 40": 0.3541,
    "40 - 60": 0.1738,
    "60 - 80": 0.079,
    "80+": 0.0069,
  },
  {
    year: 1991,
    country: "Germany",
    "0 - 20": 0.2149,
    "20 - 40": 0.3176,
    "40 - 60": 0.2627,
    "60 - 80": 0.1656,
    "80+": 0.0391,
  },
  {
    year: 1991,
    country: "Italy",
    "0 - 20": 0.2344,
    "20 - 40": 0.3016,
    "40 - 60": 0.2551,
    "60 - 80": 0.1735,
    "80+": 0.0353,
  },
  {
    year: 1991,
    country: "Japan",
    "0 - 20": 0.2592,
    "20 - 40": 0.2733,
    "40 - 60": 0.289,
    "60 - 80": 0.1541,
    "80+": 0.0244,
  },
  {
    year: 1991,
    country: "Korea, Rep.",
    "0 - 20": 0.3472,
    "20 - 40": 0.375,
    "40 - 60": 0.1961,
    "60 - 80": 0.0741,
    "80+": 0.0076,
  },
  {
    year: 1991,
    country: "Nigeria",
    "0 - 20": 0.5538,
    "20 - 40": 0.2638,
    "40 - 60": 0.1345,
    "60 - 80": 0.0458,
    "80+": 0.0021,
  },
  {
    year: 1991,
    country: "South Africa",
    "0 - 20": 0.5,
    "20 - 40": 0.293,
    "40 - 60": 0.1446,
    "60 - 80": 0.0582,
    "80+": 0.0043,
  },
  {
    year: 1991,
    country: "United Kingdom",
    "0 - 20": 0.2555,
    "20 - 40": 0.2976,
    "40 - 60": 0.2383,
    "60 - 80": 0.1709,
    "80+": 0.0376,
  },
  {
    year: 1991,
    country: "United States",
    "0 - 20": 0.288,
    "20 - 40": 0.3272,
    "40 - 60": 0.2168,
    "60 - 80": 0.1382,
    "80+": 0.0298,
  },
  {
    year: 1992,
    country: "China",
    "0 - 20": 0.3769,
    "20 - 40": 0.3565,
    "40 - 60": 0.1792,
    "60 - 80": 0.0801,
    "80+": 0.0073,
  },
  {
    year: 1992,
    country: "Germany",
    "0 - 20": 0.2144,
    "20 - 40": 0.3169,
    "40 - 60": 0.2636,
    "60 - 80": 0.1645,
    "80+": 0.0407,
  },
  {
    year: 1992,
    country: "Italy",
    "0 - 20": 0.2285,
    "20 - 40": 0.3033,
    "40 - 60": 0.256,
    "60 - 80": 0.1746,
    "80+": 0.0376,
  },
  {
    year: 1992,
    country: "Japan",
    "0 - 20": 0.252,
    "20 - 40": 0.2734,
    "40 - 60": 0.2903,
    "60 - 80": 0.1583,
    "80+": 0.026,
  },
  {
    year: 1992,
    country: "Korea, Rep.",
    "0 - 20": 0.3388,
    "20 - 40": 0.3782,
    "40 - 60": 0.1989,
    "60 - 80": 0.0761,
    "80+": 0.0081,
  },
  {
    year: 1992,
    country: "Nigeria",
    "0 - 20": 0.5537,
    "20 - 40": 0.2646,
    "40 - 60": 0.1338,
    "60 - 80": 0.0456,
    "80+": 0.0022,
  },
  {
    year: 1992,
    country: "South Africa",
    "0 - 20": 0.4948,
    "20 - 40": 0.2956,
    "40 - 60": 0.1463,
    "60 - 80": 0.0589,
    "80+": 0.0045,
  },
  {
    year: 1992,
    country: "United Kingdom",
    "0 - 20": 0.2544,
    "20 - 40": 0.2977,
    "40 - 60": 0.2396,
    "60 - 80": 0.1695,
    "80+": 0.0388,
  },
  {
    year: 1992,
    country: "United States",
    "0 - 20": 0.2882,
    "20 - 40": 0.3237,
    "40 - 60": 0.2209,
    "60 - 80": 0.1368,
    "80+": 0.0304,
  },
  {
    year: 1993,
    country: "China",
    "0 - 20": 0.3663,
    "20 - 40": 0.3587,
    "40 - 60": 0.1858,
    "60 - 80": 0.0816,
    "80+": 0.0077,
  },
  {
    year: 1993,
    country: "Germany",
    "0 - 20": 0.2144,
    "20 - 40": 0.315,
    "40 - 60": 0.2648,
    "60 - 80": 0.1641,
    "80+": 0.0417,
  },
  {
    year: 1993,
    country: "Italy",
    "0 - 20": 0.2227,
    "20 - 40": 0.3049,
    "40 - 60": 0.2568,
    "60 - 80": 0.1759,
    "80+": 0.0396,
  },
  {
    year: 1993,
    country: "Japan",
    "0 - 20": 0.2445,
    "20 - 40": 0.2745,
    "40 - 60": 0.2909,
    "60 - 80": 0.1627,
    "80+": 0.0274,
  },
  {
    year: 1993,
    country: "Korea, Rep.",
    "0 - 20": 0.332,
    "20 - 40": 0.38,
    "40 - 60": 0.2013,
    "60 - 80": 0.0783,
    "80+": 0.0084,
  },
  {
    year: 1993,
    country: "Nigeria",
    "0 - 20": 0.5531,
    "20 - 40": 0.2659,
    "40 - 60": 0.1333,
    "60 - 80": 0.0455,
    "80+": 0.0022,
  },
  {
    year: 1993,
    country: "South Africa",
    "0 - 20": 0.4891,
    "20 - 40": 0.2983,
    "40 - 60": 0.1483,
    "60 - 80": 0.0598,
    "80+": 0.0046,
  },
  {
    year: 1993,
    country: "United Kingdom",
    "0 - 20": 0.2538,
    "20 - 40": 0.2976,
    "40 - 60": 0.2406,
    "60 - 80": 0.1684,
    "80+": 0.0397,
  },
  {
    year: 1993,
    country: "United States",
    "0 - 20": 0.2886,
    "20 - 40": 0.3199,
    "40 - 60": 0.2253,
    "60 - 80": 0.1355,
    "80+": 0.0307,
  },
  {
    year: 1994,
    country: "China",
    "0 - 20": 0.3571,
    "20 - 40": 0.3599,
    "40 - 60": 0.1919,
    "60 - 80": 0.0831,
    "80+": 0.0079,
  },
  {
    year: 1994,
    country: "Germany",
    "0 - 20": 0.2148,
    "20 - 40": 0.3125,
    "40 - 60": 0.2659,
    "60 - 80": 0.1652,
    "80+": 0.0417,
  },
  {
    year: 1994,
    country: "Italy",
    "0 - 20": 0.2174,
    "20 - 40": 0.3061,
    "40 - 60": 0.2576,
    "60 - 80": 0.1781,
    "80+": 0.0409,
  },
  {
    year: 1994,
    country: "Japan",
    "0 - 20": 0.2373,
    "20 - 40": 0.2758,
    "40 - 60": 0.291,
    "60 - 80": 0.1672,
    "80+": 0.0287,
  },
  {
    year: 1994,
    country: "Korea, Rep.",
    "0 - 20": 0.3253,
    "20 - 40": 0.3806,
    "40 - 60": 0.2047,
    "60 - 80": 0.0807,
    "80+": 0.0086,
  },
  {
    year: 1994,
    country: "Nigeria",
    "0 - 20": 0.552,
    "20 - 40": 0.2674,
    "40 - 60": 0.1329,
    "60 - 80": 0.0455,
    "80+": 0.0021,
  },
  {
    year: 1994,
    country: "South Africa",
    "0 - 20": 0.483,
    "20 - 40": 0.3011,
    "40 - 60": 0.1506,
    "60 - 80": 0.0608,
    "80+": 0.0046,
  },
  {
    year: 1994,
    country: "United Kingdom",
    "0 - 20": 0.2535,
    "20 - 40": 0.297,
    "40 - 60": 0.2418,
    "60 - 80": 0.1677,
    "80+": 0.04,
  },
  {
    year: 1994,
    country: "United States",
    "0 - 20": 0.2889,
    "20 - 40": 0.3157,
    "40 - 60": 0.2302,
    "60 - 80": 0.1344,
    "80+": 0.0308,
  },
  {
    year: 1995,
    country: "China",
    "0 - 20": 0.3506,
    "20 - 40": 0.3598,
    "40 - 60": 0.197,
    "60 - 80": 0.0845,
    "80+": 0.0081,
  },
  {
    year: 1995,
    country: "Germany",
    "0 - 20": 0.2152,
    "20 - 40": 0.3096,
    "40 - 60": 0.2663,
    "60 - 80": 0.1684,
    "80+": 0.0406,
  },
  {
    year: 1995,
    country: "Italy",
    "0 - 20": 0.2126,
    "20 - 40": 0.3064,
    "40 - 60": 0.2584,
    "60 - 80": 0.1814,
    "80+": 0.0412,
  },
  {
    year: 1995,
    country: "Japan",
    "0 - 20": 0.2308,
    "20 - 40": 0.2768,
    "40 - 60": 0.2908,
    "60 - 80": 0.1718,
    "80+": 0.0298,
  },
  {
    year: 1995,
    country: "Korea, Rep.",
    "0 - 20": 0.3179,
    "20 - 40": 0.3803,
    "40 - 60": 0.2093,
    "60 - 80": 0.0836,
    "80+": 0.0088,
  },
  {
    year: 1995,
    country: "Nigeria",
    "0 - 20": 0.5505,
    "20 - 40": 0.2692,
    "40 - 60": 0.1327,
    "60 - 80": 0.0455,
    "80+": 0.002,
  },
  {
    year: 1995,
    country: "South Africa",
    "0 - 20": 0.4767,
    "20 - 40": 0.3038,
    "40 - 60": 0.1529,
    "60 - 80": 0.0621,
    "80+": 0.0045,
  },
  {
    year: 1995,
    country: "United Kingdom",
    "0 - 20": 0.2533,
    "20 - 40": 0.2958,
    "40 - 60": 0.2435,
    "60 - 80": 0.1676,
    "80+": 0.0398,
  },
  {
    year: 1995,
    country: "United States",
    "0 - 20": 0.289,
    "20 - 40": 0.3112,
    "40 - 60": 0.2353,
    "60 - 80": 0.1336,
    "80+": 0.0308,
  },
  {
    year: 1996,
    country: "China",
    "0 - 20": 0.3413,
    "20 - 40": 0.3615,
    "40 - 60": 0.2024,
    "60 - 80": 0.0859,
    "80+": 0.0088,
  },
  {
    year: 1996,
    country: "Germany",
    "0 - 20": 0.2144,
    "20 - 40": 0.3055,
    "40 - 60": 0.2666,
    "60 - 80": 0.173,
    "80+": 0.0404,
  },
  {
    year: 1996,
    country: "Italy",
    "0 - 20": 0.2085,
    "20 - 40": 0.3062,
    "40 - 60": 0.2585,
    "60 - 80": 0.1851,
    "80+": 0.0417,
  },
  {
    year: 1996,
    country: "Japan",
    "0 - 20": 0.2247,
    "20 - 40": 0.2778,
    "40 - 60": 0.2902,
    "60 - 80": 0.1759,
    "80+": 0.0314,
  },
  {
    year: 1996,
    country: "Korea, Rep.",
    "0 - 20": 0.3135,
    "20 - 40": 0.3772,
    "40 - 60": 0.2139,
    "60 - 80": 0.0861,
    "80+": 0.0093,
  },
  {
    year: 1996,
    country: "Nigeria",
    "0 - 20": 0.5501,
    "20 - 40": 0.2705,
    "40 - 60": 0.132,
    "60 - 80": 0.0453,
    "80+": 0.0021,
  },
  {
    year: 1996,
    country: "South Africa",
    "0 - 20": 0.4719,
    "20 - 40": 0.3064,
    "40 - 60": 0.1545,
    "60 - 80": 0.0623,
    "80+": 0.0049,
  },
  {
    year: 1996,
    country: "United Kingdom",
    "0 - 20": 0.2532,
    "20 - 40": 0.2946,
    "40 - 60": 0.2448,
    "60 - 80": 0.1669,
    "80+": 0.0404,
  },
  {
    year: 1996,
    country: "United States",
    "0 - 20": 0.2894,
    "20 - 40": 0.3065,
    "40 - 60": 0.2405,
    "60 - 80": 0.1322,
    "80+": 0.0314,
  },
  {
    year: 1997,
    country: "China",
    "0 - 20": 0.3367,
    "20 - 40": 0.3607,
    "40 - 60": 0.2063,
    "60 - 80": 0.087,
    "80+": 0.0093,
  },
  {
    year: 1997,
    country: "Germany",
    "0 - 20": 0.2143,
    "20 - 40": 0.301,
    "40 - 60": 0.2662,
    "60 - 80": 0.1792,
    "80+": 0.0393,
  },
  {
    year: 1997,
    country: "Italy",
    "0 - 20": 0.2049,
    "20 - 40": 0.3054,
    "40 - 60": 0.2585,
    "60 - 80": 0.1899,
    "80+": 0.0414,
  },
  {
    year: 1997,
    country: "Japan",
    "0 - 20": 0.2196,
    "20 - 40": 0.2783,
    "40 - 60": 0.2891,
    "60 - 80": 0.1801,
    "80+": 0.0329,
  },
  {
    year: 1997,
    country: "Korea, Rep.",
    "0 - 20": 0.3074,
    "20 - 40": 0.3736,
    "40 - 60": 0.2201,
    "60 - 80": 0.0892,
    "80+": 0.0097,
  },
  {
    year: 1997,
    country: "Nigeria",
    "0 - 20": 0.5491,
    "20 - 40": 0.272,
    "40 - 60": 0.1315,
    "60 - 80": 0.0451,
    "80+": 0.0022,
  },
  {
    year: 1997,
    country: "South Africa",
    "0 - 20": 0.4672,
    "20 - 40": 0.309,
    "40 - 60": 0.1562,
    "60 - 80": 0.0625,
    "80+": 0.0051,
  },
  {
    year: 1997,
    country: "United Kingdom",
    "0 - 20": 0.2531,
    "20 - 40": 0.293,
    "40 - 60": 0.2465,
    "60 - 80": 0.1668,
    "80+": 0.0406,
  },
  {
    year: 1997,
    country: "United States",
    "0 - 20": 0.2893,
    "20 - 40": 0.302,
    "40 - 60": 0.2458,
    "60 - 80": 0.1311,
    "80+": 0.0319,
  },
  {
    year: 1998,
    country: "China",
    "0 - 20": 0.3346,
    "20 - 40": 0.3585,
    "40 - 60": 0.2093,
    "60 - 80": 0.0879,
    "80+": 0.0097,
  },
  {
    year: 1998,
    country: "Germany",
    "0 - 20": 0.2143,
    "20 - 40": 0.2962,
    "40 - 60": 0.2657,
    "60 - 80": 0.186,
    "80+": 0.0378,
  },
  {
    year: 1998,
    country: "Italy",
    "0 - 20": 0.2017,
    "20 - 40": 0.3039,
    "40 - 60": 0.2586,
    "60 - 80": 0.195,
    "80+": 0.0408,
  },
  {
    year: 1998,
    country: "Japan",
    "0 - 20": 0.2153,
    "20 - 40": 0.2783,
    "40 - 60": 0.2877,
    "60 - 80": 0.1844,
    "80+": 0.0342,
  },
  {
    year: 1998,
    country: "Korea, Rep.",
    "0 - 20": 0.3004,
    "20 - 40": 0.3697,
    "40 - 60": 0.2274,
    "60 - 80": 0.0926,
    "80+": 0.01,
  },
  {
    year: 1998,
    country: "Nigeria",
    "0 - 20": 0.5479,
    "20 - 40": 0.2737,
    "40 - 60": 0.1312,
    "60 - 80": 0.045,
    "80+": 0.0023,
  },
  {
    year: 1998,
    country: "South Africa",
    "0 - 20": 0.4622,
    "20 - 40": 0.3118,
    "40 - 60": 0.158,
    "60 - 80": 0.0629,
    "80+": 0.0052,
  },
  {
    year: 1998,
    country: "United Kingdom",
    "0 - 20": 0.253,
    "20 - 40": 0.291,
    "40 - 60": 0.2485,
    "60 - 80": 0.167,
    "80+": 0.0405,
  },
  {
    year: 1998,
    country: "United States",
    "0 - 20": 0.2888,
    "20 - 40": 0.2976,
    "40 - 60": 0.2511,
    "60 - 80": 0.1303,
    "80+": 0.0322,
  },
  {
    year: 1999,
    country: "China",
    "0 - 20": 0.332,
    "20 - 40": 0.3561,
    "40 - 60": 0.2131,
    "60 - 80": 0.089,
    "80+": 0.0099,
  },
  {
    year: 1999,
    country: "Germany",
    "0 - 20": 0.2139,
    "20 - 40": 0.2912,
    "40 - 60": 0.2661,
    "60 - 80": 0.1922,
    "80+": 0.0366,
  },
  {
    year: 1999,
    country: "Italy",
    "0 - 20": 0.1991,
    "20 - 40": 0.3017,
    "40 - 60": 0.2592,
    "60 - 80": 0.1995,
    "80+": 0.0405,
  },
  {
    year: 1999,
    country: "Japan",
    "0 - 20": 0.2114,
    "20 - 40": 0.278,
    "40 - 60": 0.2862,
    "60 - 80": 0.1888,
    "80+": 0.0356,
  },
  {
    year: 1999,
    country: "Korea, Rep.",
    "0 - 20": 0.2935,
    "20 - 40": 0.3654,
    "40 - 60": 0.2348,
    "60 - 80": 0.0959,
    "80+": 0.0104,
  },
  {
    year: 1999,
    country: "Nigeria",
    "0 - 20": 0.5464,
    "20 - 40": 0.2755,
    "40 - 60": 0.131,
    "60 - 80": 0.0449,
    "80+": 0.0022,
  },
  {
    year: 1999,
    country: "South Africa",
    "0 - 20": 0.4565,
    "20 - 40": 0.3151,
    "40 - 60": 0.16,
    "60 - 80": 0.0633,
    "80+": 0.0052,
  },
  {
    year: 1999,
    country: "United Kingdom",
    "0 - 20": 0.2525,
    "20 - 40": 0.2887,
    "40 - 60": 0.2511,
    "60 - 80": 0.1673,
    "80+": 0.0404,
  },
  {
    year: 1999,
    country: "United States",
    "0 - 20": 0.2883,
    "20 - 40": 0.2934,
    "40 - 60": 0.2562,
    "60 - 80": 0.1298,
    "80+": 0.0323,
  },
  {
    year: 2000,
    country: "China",
    "0 - 20": 0.3275,
    "20 - 40": 0.354,
    "40 - 60": 0.2181,
    "60 - 80": 0.0904,
    "80+": 0.0099,
  },
  {
    year: 2000,
    country: "Germany",
    "0 - 20": 0.2128,
    "20 - 40": 0.2861,
    "40 - 60": 0.2677,
    "60 - 80": 0.1973,
    "80+": 0.0361,
  },
  {
    year: 2000,
    country: "Italy",
    "0 - 20": 0.197,
    "20 - 40": 0.2986,
    "40 - 60": 0.2607,
    "60 - 80": 0.2029,
    "80+": 0.0408,
  },
  {
    year: 2000,
    country: "Japan",
    "0 - 20": 0.2075,
    "20 - 40": 0.2775,
    "40 - 60": 0.2846,
    "60 - 80": 0.1934,
    "80+": 0.037,
  },
  {
    year: 2000,
    country: "Korea, Rep.",
    "0 - 20": 0.2872,
    "20 - 40": 0.3611,
    "40 - 60": 0.242,
    "60 - 80": 0.099,
    "80+": 0.0107,
  },
  {
    year: 2000,
    country: "Nigeria",
    "0 - 20": 0.5449,
    "20 - 40": 0.2774,
    "40 - 60": 0.1308,
    "60 - 80": 0.0449,
    "80+": 0.002,
  },
  {
    year: 2000,
    country: "South Africa",
    "0 - 20": 0.4499,
    "20 - 40": 0.3189,
    "40 - 60": 0.1622,
    "60 - 80": 0.0639,
    "80+": 0.005,
  },
  {
    year: 2000,
    country: "United Kingdom",
    "0 - 20": 0.2518,
    "20 - 40": 0.2861,
    "40 - 60": 0.2541,
    "60 - 80": 0.1678,
    "80+": 0.0403,
  },
  {
    year: 2000,
    country: "United States",
    "0 - 20": 0.2878,
    "20 - 40": 0.2893,
    "40 - 60": 0.2608,
    "60 - 80": 0.1296,
    "80+": 0.0324,
  },
  {
    year: 2001,
    country: "China",
    "0 - 20": 0.3253,
    "20 - 40": 0.3503,
    "40 - 60": 0.223,
    "60 - 80": 0.091,
    "80+": 0.0104,
  },
  {
    year: 2001,
    country: "Germany",
    "0 - 20": 0.2119,
    "20 - 40": 0.2807,
    "40 - 60": 0.2701,
    "60 - 80": 0.2,
    "80+": 0.0373,
  },
  {
    year: 2001,
    country: "Italy",
    "0 - 20": 0.195,
    "20 - 40": 0.296,
    "40 - 60": 0.2634,
    "60 - 80": 0.203,
    "80+": 0.0426,
  },
  {
    year: 2001,
    country: "Japan",
    "0 - 20": 0.2041,
    "20 - 40": 0.2769,
    "40 - 60": 0.2827,
    "60 - 80": 0.1973,
    "80+": 0.039,
  },
  {
    year: 2001,
    country: "Korea, Rep.",
    "0 - 20": 0.279,
    "20 - 40": 0.358,
    "40 - 60": 0.2496,
    "60 - 80": 0.1018,
    "80+": 0.0115,
  },
  {
    year: 2001,
    country: "Nigeria",
    "0 - 20": 0.5445,
    "20 - 40": 0.2785,
    "40 - 60": 0.1301,
    "60 - 80": 0.0447,
    "80+": 0.0021,
  },
  {
    year: 2001,
    country: "South Africa",
    "0 - 20": 0.4449,
    "20 - 40": 0.322,
    "40 - 60": 0.1637,
    "60 - 80": 0.064,
    "80+": 0.0053,
  },
  {
    year: 2001,
    country: "United Kingdom",
    "0 - 20": 0.2514,
    "20 - 40": 0.2834,
    "40 - 60": 0.2566,
    "60 - 80": 0.1671,
    "80+": 0.0414,
  },
  {
    year: 2001,
    country: "United States",
    "0 - 20": 0.2867,
    "20 - 40": 0.2854,
    "40 - 60": 0.2655,
    "60 - 80": 0.1293,
    "80+": 0.0332,
  },
  {
    year: 2002,
    country: "China",
    "0 - 20": 0.3212,
    "20 - 40": 0.3471,
    "40 - 60": 0.2291,
    "60 - 80": 0.0919,
    "80+": 0.0107,
  },
  {
    year: 2002,
    country: "Germany",
    "0 - 20": 0.2101,
    "20 - 40": 0.2756,
    "40 - 60": 0.2736,
    "60 - 80": 0.202,
    "80+": 0.0388,
  },
  {
    year: 2002,
    country: "Italy",
    "0 - 20": 0.1936,
    "20 - 40": 0.2925,
    "40 - 60": 0.267,
    "60 - 80": 0.2022,
    "80+": 0.0446,
  },
  {
    year: 2002,
    country: "Japan",
    "0 - 20": 0.2005,
    "20 - 40": 0.2761,
    "40 - 60": 0.2811,
    "60 - 80": 0.2013,
    "80+": 0.041,
  },
  {
    year: 2002,
    country: "Korea, Rep.",
    "0 - 20": 0.2713,
    "20 - 40": 0.3548,
    "40 - 60": 0.257,
    "60 - 80": 0.1045,
    "80+": 0.0123,
  },
  {
    year: 2002,
    country: "Nigeria",
    "0 - 20": 0.544,
    "20 - 40": 0.2798,
    "40 - 60": 0.1296,
    "60 - 80": 0.0445,
    "80+": 0.0022,
  },
  {
    year: 2002,
    country: "South Africa",
    "0 - 20": 0.4392,
    "20 - 40": 0.3257,
    "40 - 60": 0.1654,
    "60 - 80": 0.0642,
    "80+": 0.0055,
  },
  {
    year: 2002,
    country: "United Kingdom",
    "0 - 20": 0.2505,
    "20 - 40": 0.2808,
    "40 - 60": 0.2596,
    "60 - 80": 0.1666,
    "80+": 0.0425,
  },
  {
    year: 2002,
    country: "United States",
    "0 - 20": 0.2859,
    "20 - 40": 0.2815,
    "40 - 60": 0.2697,
    "60 - 80": 0.1292,
    "80+": 0.0338,
  },
  {
    year: 2003,
    country: "China",
    "0 - 20": 0.3155,
    "20 - 40": 0.3442,
    "40 - 60": 0.2361,
    "60 - 80": 0.0932,
    "80+": 0.0109,
  },
  {
    year: 2003,
    country: "Germany",
    "0 - 20": 0.2075,
    "20 - 40": 0.2707,
    "40 - 60": 0.2781,
    "60 - 80": 0.2034,
    "80+": 0.0404,
  },
  {
    year: 2003,
    country: "Italy",
    "0 - 20": 0.1926,
    "20 - 40": 0.2882,
    "40 - 60": 0.2712,
    "60 - 80": 0.2011,
    "80+": 0.0468,
  },
  {
    year: 2003,
    country: "Japan",
    "0 - 20": 0.197,
    "20 - 40": 0.275,
    "40 - 60": 0.2794,
    "60 - 80": 0.2055,
    "80+": 0.0431,
  },
  {
    year: 2003,
    country: "Korea, Rep.",
    "0 - 20": 0.2643,
    "20 - 40": 0.3514,
    "40 - 60": 0.2641,
    "60 - 80": 0.1072,
    "80+": 0.0131,
  },
  {
    year: 2003,
    country: "Nigeria",
    "0 - 20": 0.5433,
    "20 - 40": 0.281,
    "40 - 60": 0.1292,
    "60 - 80": 0.0443,
    "80+": 0.0022,
  },
  {
    year: 2003,
    country: "South Africa",
    "0 - 20": 0.433,
    "20 - 40": 0.3299,
    "40 - 60": 0.1671,
    "60 - 80": 0.0645,
    "80+": 0.0056,
  },
  {
    year: 2003,
    country: "United Kingdom",
    "0 - 20": 0.2491,
    "20 - 40": 0.2784,
    "40 - 60": 0.2628,
    "60 - 80": 0.1664,
    "80+": 0.0432,
  },
  {
    year: 2003,
    country: "United States",
    "0 - 20": 0.285,
    "20 - 40": 0.2781,
    "40 - 60": 0.2732,
    "60 - 80": 0.1296,
    "80+": 0.0341,
  },
  {
    year: 2004,
    country: "China",
    "0 - 20": 0.309,
    "20 - 40": 0.3415,
    "40 - 60": 0.2436,
    "60 - 80": 0.0949,
    "80+": 0.011,
  },
  {
    year: 2004,
    country: "Germany",
    "0 - 20": 0.2047,
    "20 - 40": 0.266,
    "40 - 60": 0.283,
    "60 - 80": 0.2046,
    "80+": 0.0418,
  },
  {
    year: 2004,
    country: "Italy",
    "0 - 20": 0.1919,
    "20 - 40": 0.2834,
    "40 - 60": 0.2754,
    "60 - 80": 0.2006,
    "80+": 0.0487,
  },
  {
    year: 2004,
    country: "Japan",
    "0 - 20": 0.1938,
    "20 - 40": 0.2735,
    "40 - 60": 0.2773,
    "60 - 80": 0.2102,
    "80+": 0.0453,
  },
  {
    year: 2004,
    country: "Korea, Rep.",
    "0 - 20": 0.2578,
    "20 - 40": 0.3472,
    "40 - 60": 0.2711,
    "60 - 80": 0.1102,
    "80+": 0.0137,
  },
  {
    year: 2004,
    country: "Nigeria",
    "0 - 20": 0.5426,
    "20 - 40": 0.2821,
    "40 - 60": 0.129,
    "60 - 80": 0.0442,
    "80+": 0.0021,
  },
  {
    year: 2004,
    country: "South Africa",
    "0 - 20": 0.4267,
    "20 - 40": 0.3342,
    "40 - 60": 0.1689,
    "60 - 80": 0.0648,
    "80+": 0.0055,
  },
  {
    year: 2004,
    country: "United Kingdom",
    "0 - 20": 0.2474,
    "20 - 40": 0.2763,
    "40 - 60": 0.2657,
    "60 - 80": 0.1669,
    "80+": 0.0437,
  },
  {
    year: 2004,
    country: "United States",
    "0 - 20": 0.2838,
    "20 - 40": 0.2753,
    "40 - 60": 0.2761,
    "60 - 80": 0.1308,
    "80+": 0.0342,
  },
  {
    year: 2005,
    country: "China",
    "0 - 20": 0.3019,
    "20 - 40": 0.339,
    "40 - 60": 0.2514,
    "60 - 80": 0.0968,
    "80+": 0.011,
  },
  {
    year: 2005,
    country: "Germany",
    "0 - 20": 0.2018,
    "20 - 40": 0.2615,
    "40 - 60": 0.2881,
    "60 - 80": 0.2058,
    "80+": 0.0429,
  },
  {
    year: 2005,
    country: "Italy",
    "0 - 20": 0.1912,
    "20 - 40": 0.2782,
    "40 - 60": 0.2792,
    "60 - 80": 0.201,
    "80+": 0.0503,
  },
  {
    year: 2005,
    country: "Japan",
    "0 - 20": 0.191,
    "20 - 40": 0.2714,
    "40 - 60": 0.2745,
    "60 - 80": 0.2153,
    "80+": 0.0477,
  },
  {
    year: 2005,
    country: "Korea, Rep.",
    "0 - 20": 0.252,
    "20 - 40": 0.342,
    "40 - 60": 0.278,
    "60 - 80": 0.1137,
    "80+": 0.0142,
  },
  {
    year: 2005,
    country: "Nigeria",
    "0 - 20": 0.542,
    "20 - 40": 0.2832,
    "40 - 60": 0.1288,
    "60 - 80": 0.0441,
    "80+": 0.0019,
  },
  {
    year: 2005,
    country: "South Africa",
    "0 - 20": 0.4205,
    "20 - 40": 0.3385,
    "40 - 60": 0.1707,
    "60 - 80": 0.0651,
    "80+": 0.0053,
  },
  {
    year: 2005,
    country: "United Kingdom",
    "0 - 20": 0.2455,
    "20 - 40": 0.2744,
    "40 - 60": 0.2679,
    "60 - 80": 0.1684,
    "80+": 0.0438,
  },
  {
    year: 2005,
    country: "United States",
    "0 - 20": 0.2819,
    "20 - 40": 0.2732,
    "40 - 60": 0.2781,
    "60 - 80": 0.1327,
    "80+": 0.0341,
  },
  {
    year: 2006,
    country: "China",
    "0 - 20": 0.2935,
    "20 - 40": 0.3373,
    "40 - 60": 0.2594,
    "60 - 80": 0.0983,
    "80+": 0.0115,
  },
  {
    year: 2006,
    country: "Germany",
    "0 - 20": 0.1987,
    "20 - 40": 0.2565,
    "40 - 60": 0.2933,
    "60 - 80": 0.2064,
    "80+": 0.045,
  },
  {
    year: 2006,
    country: "Italy",
    "0 - 20": 0.1912,
    "20 - 40": 0.2731,
    "40 - 60": 0.2818,
    "60 - 80": 0.2014,
    "80+": 0.0525,
  },
  {
    year: 2006,
    country: "Japan",
    "0 - 20": 0.1883,
    "20 - 40": 0.2695,
    "40 - 60": 0.2715,
    "60 - 80": 0.2202,
    "80+": 0.0506,
  },
  {
    year: 2006,
    country: "Korea, Rep.",
    "0 - 20": 0.2469,
    "20 - 40": 0.3356,
    "40 - 60": 0.285,
    "60 - 80": 0.1173,
    "80+": 0.0152,
  },
  {
    year: 2006,
    country: "Nigeria",
    "0 - 20": 0.5423,
    "20 - 40": 0.2835,
    "40 - 60": 0.1282,
    "60 - 80": 0.0439,
    "80+": 0.0021,
  },
  {
    year: 2006,
    country: "South Africa",
    "0 - 20": 0.415,
    "20 - 40": 0.3417,
    "40 - 60": 0.1726,
    "60 - 80": 0.0651,
    "80+": 0.0056,
  },
  {
    year: 2006,
    country: "United Kingdom",
    "0 - 20": 0.2442,
    "20 - 40": 0.2721,
    "40 - 60": 0.2691,
    "60 - 80": 0.1699,
    "80+": 0.0447,
  },
  {
    year: 2006,
    country: "United States",
    "0 - 20": 0.2811,
    "20 - 40": 0.2709,
    "40 - 60": 0.2788,
    "60 - 80": 0.1345,
    "80+": 0.0348,
  },
  {
    year: 2007,
    country: "China",
    "0 - 20": 0.2841,
    "20 - 40": 0.3361,
    "40 - 60": 0.2677,
    "60 - 80": 0.1001,
    "80+": 0.012,
  },
  {
    year: 2007,
    country: "Germany",
    "0 - 20": 0.1957,
    "20 - 40": 0.2518,
    "40 - 60": 0.2988,
    "60 - 80": 0.2069,
    "80+": 0.0468,
  },
  {
    year: 2007,
    country: "Italy",
    "0 - 20": 0.191,
    "20 - 40": 0.2682,
    "40 - 60": 0.2838,
    "60 - 80": 0.2027,
    "80+": 0.0543,
  },
  {
    year: 2007,
    country: "Japan",
    "0 - 20": 0.1862,
    "20 - 40": 0.267,
    "40 - 60": 0.2679,
    "60 - 80": 0.2255,
    "80+": 0.0534,
  },
  {
    year: 2007,
    country: "Korea, Rep.",
    "0 - 20": 0.243,
    "20 - 40": 0.328,
    "40 - 60": 0.2916,
    "60 - 80": 0.1213,
    "80+": 0.016,
  },
  {
    year: 2007,
    country: "Nigeria",
    "0 - 20": 0.5424,
    "20 - 40": 0.2838,
    "40 - 60": 0.1278,
    "60 - 80": 0.0438,
    "80+": 0.0022,
  },
  {
    year: 2007,
    country: "South Africa",
    "0 - 20": 0.4098,
    "20 - 40": 0.3448,
    "40 - 60": 0.1745,
    "60 - 80": 0.0651,
    "80+": 0.0059,
  },
  {
    year: 2007,
    country: "United Kingdom",
    "0 - 20": 0.2429,
    "20 - 40": 0.2701,
    "40 - 60": 0.2694,
    "60 - 80": 0.1722,
    "80+": 0.0453,
  },
  {
    year: 2007,
    country: "United States",
    "0 - 20": 0.2797,
    "20 - 40": 0.2692,
    "40 - 60": 0.2788,
    "60 - 80": 0.1369,
    "80+": 0.0354,
  },
  {
    year: 2008,
    country: "China",
    "0 - 20": 0.2747,
    "20 - 40": 0.3349,
    "40 - 60": 0.2759,
    "60 - 80": 0.1023,
    "80+": 0.0123,
  },
  {
    year: 2008,
    country: "Germany",
    "0 - 20": 0.1928,
    "20 - 40": 0.2474,
    "40 - 60": 0.3039,
    "60 - 80": 0.2074,
    "80+": 0.0484,
  },
  {
    year: 2008,
    country: "Italy",
    "0 - 20": 0.1906,
    "20 - 40": 0.2633,
    "40 - 60": 0.2857,
    "60 - 80": 0.2047,
    "80+": 0.0558,
  },
  {
    year: 2008,
    country: "Japan",
    "0 - 20": 0.1844,
    "20 - 40": 0.264,
    "40 - 60": 0.2643,
    "60 - 80": 0.2311,
    "80+": 0.0563,
  },
  {
    year: 2008,
    country: "Korea, Rep.",
    "0 - 20": 0.2398,
    "20 - 40": 0.3198,
    "40 - 60": 0.2979,
    "60 - 80": 0.1256,
    "80+": 0.0169,
  },
  {
    year: 2008,
    country: "Nigeria",
    "0 - 20": 0.5424,
    "20 - 40": 0.2841,
    "40 - 60": 0.1276,
    "60 - 80": 0.0437,
    "80+": 0.0022,
  },
  {
    year: 2008,
    country: "South Africa",
    "0 - 20": 0.4048,
    "20 - 40": 0.3478,
    "40 - 60": 0.1763,
    "60 - 80": 0.0651,
    "80+": 0.006,
  },
  {
    year: 2008,
    country: "United Kingdom",
    "0 - 20": 0.2416,
    "20 - 40": 0.2688,
    "40 - 60": 0.269,
    "60 - 80": 0.1749,
    "80+": 0.0458,
  },
  {
    year: 2008,
    country: "United States",
    "0 - 20": 0.2779,
    "20 - 40": 0.268,
    "40 - 60": 0.2782,
    "60 - 80": 0.14,
    "80+": 0.0359,
  },
  {
    year: 2009,
    country: "China",
    "0 - 20": 0.2661,
    "20 - 40": 0.3328,
    "40 - 60": 0.2833,
    "60 - 80": 0.1053,
    "80+": 0.0125,
  },
  {
    year: 2009,
    country: "Germany",
    "0 - 20": 0.19,
    "20 - 40": 0.2438,
    "40 - 60": 0.3081,
    "60 - 80": 0.2084,
    "80+": 0.0497,
  },
  {
    year: 2009,
    country: "Italy",
    "0 - 20": 0.19,
    "20 - 40": 0.2584,
    "40 - 60": 0.2877,
    "60 - 80": 0.2068,
    "80+": 0.0571,
  },
  {
    year: 2009,
    country: "Japan",
    "0 - 20": 0.1827,
    "20 - 40": 0.2601,
    "40 - 60": 0.2617,
    "60 - 80": 0.2363,
    "80+": 0.0591,
  },
  {
    year: 2009,
    country: "Korea, Rep.",
    "0 - 20": 0.2361,
    "20 - 40": 0.3119,
    "40 - 60": 0.3042,
    "60 - 80": 0.1301,
    "80+": 0.0177,
  },
  {
    year: 2009,
    country: "Nigeria",
    "0 - 20": 0.5425,
    "20 - 40": 0.2842,
    "40 - 60": 0.1277,
    "60 - 80": 0.0436,
    "80+": 0.0021,
  },
  {
    year: 2009,
    country: "South Africa",
    "0 - 20": 0.4001,
    "20 - 40": 0.3505,
    "40 - 60": 0.1781,
    "60 - 80": 0.0654,
    "80+": 0.006,
  },
  {
    year: 2009,
    country: "United Kingdom",
    "0 - 20": 0.24,
    "20 - 40": 0.268,
    "40 - 60": 0.2684,
    "60 - 80": 0.1775,
    "80+": 0.0461,
  },
  {
    year: 2009,
    country: "United States",
    "0 - 20": 0.2759,
    "20 - 40": 0.2672,
    "40 - 60": 0.2772,
    "60 - 80": 0.1435,
    "80+": 0.0362,
  },
  {
    year: 2010,
    country: "China",
    "0 - 20": 0.259,
    "20 - 40": 0.3295,
    "40 - 60": 0.2895,
    "60 - 80": 0.1094,
    "80+": 0.0126,
  },
  {
    year: 2010,
    country: "Germany",
    "0 - 20": 0.1874,
    "20 - 40": 0.241,
    "40 - 60": 0.3109,
    "60 - 80": 0.21,
    "80+": 0.0507,
  },
  {
    year: 2010,
    country: "Italy",
    "0 - 20": 0.1892,
    "20 - 40": 0.2535,
    "40 - 60": 0.2903,
    "60 - 80": 0.2088,
    "80+": 0.0583,
  },
  {
    year: 2010,
    country: "Japan",
    "0 - 20": 0.1812,
    "20 - 40": 0.2554,
    "40 - 60": 0.2605,
    "60 - 80": 0.2409,
    "80+": 0.062,
  },
  {
    year: 2010,
    country: "Korea, Rep.",
    "0 - 20": 0.2316,
    "20 - 40": 0.3047,
    "40 - 60": 0.3104,
    "60 - 80": 0.1346,
    "80+": 0.0188,
  },
  {
    year: 2010,
    country: "Nigeria",
    "0 - 20": 0.5426,
    "20 - 40": 0.2841,
    "40 - 60": 0.1279,
    "60 - 80": 0.0435,
    "80+": 0.0019,
  },
  {
    year: 2010,
    country: "South Africa",
    "0 - 20": 0.3958,
    "20 - 40": 0.3527,
    "40 - 60": 0.1798,
    "60 - 80": 0.0659,
    "80+": 0.0058,
  },
  {
    year: 2010,
    country: "United Kingdom",
    "0 - 20": 0.2381,
    "20 - 40": 0.2679,
    "40 - 60": 0.2679,
    "60 - 80": 0.1797,
    "80+": 0.0464,
  },
  {
    year: 2010,
    country: "United States",
    "0 - 20": 0.2739,
    "20 - 40": 0.2665,
    "40 - 60": 0.2758,
    "60 - 80": 0.1475,
    "80+": 0.0363,
  },
  {
    year: 2011,
    country: "China",
    "0 - 20": 0.2537,
    "20 - 40": 0.3251,
    "40 - 60": 0.2942,
    "60 - 80": 0.1135,
    "80+": 0.0135,
  },
  {
    year: 2011,
    country: "Germany",
    "0 - 20": 0.1864,
    "20 - 40": 0.2395,
    "40 - 60": 0.3112,
    "60 - 80": 0.2109,
    "80+": 0.052,
  },
  {
    year: 2011,
    country: "Italy",
    "0 - 20": 0.1888,
    "20 - 40": 0.2484,
    "40 - 60": 0.2933,
    "60 - 80": 0.2095,
    "80+": 0.06,
  },
  {
    year: 2011,
    country: "Japan",
    "0 - 20": 0.1803,
    "20 - 40": 0.2502,
    "40 - 60": 0.2605,
    "60 - 80": 0.2441,
    "80+": 0.0649,
  },
  {
    year: 2011,
    country: "Korea, Rep.",
    "0 - 20": 0.2265,
    "20 - 40": 0.2995,
    "40 - 60": 0.3157,
    "60 - 80": 0.1382,
    "80+": 0.0201,
  },
  {
    year: 2011,
    country: "Nigeria",
    "0 - 20": 0.5432,
    "20 - 40": 0.2836,
    "40 - 60": 0.1279,
    "60 - 80": 0.0433,
    "80+": 0.0021,
  },
  {
    year: 2011,
    country: "South Africa",
    "0 - 20": 0.3925,
    "20 - 40": 0.3525,
    "40 - 60": 0.1821,
    "60 - 80": 0.0666,
    "80+": 0.0062,
  },
  {
    year: 2011,
    country: "United Kingdom",
    "0 - 20": 0.2378,
    "20 - 40": 0.2669,
    "40 - 60": 0.2674,
    "60 - 80": 0.1806,
    "80+": 0.0473,
  },
  {
    year: 2011,
    country: "United States",
    "0 - 20": 0.2715,
    "20 - 40": 0.267,
    "40 - 60": 0.2737,
    "60 - 80": 0.151,
    "80+": 0.0368,
  },
  {
    year: 2012,
    country: "China",
    "0 - 20": 0.2499,
    "20 - 40": 0.3195,
    "40 - 60": 0.2979,
    "60 - 80": 0.1186,
    "80+": 0.0142,
  },
  {
    year: 2012,
    country: "Germany",
    "0 - 20": 0.1856,
    "20 - 40": 0.2389,
    "40 - 60": 0.31,
    "60 - 80": 0.2125,
    "80+": 0.0529,
  },
  {
    year: 2012,
    country: "Italy",
    "0 - 20": 0.1879,
    "20 - 40": 0.2435,
    "40 - 60": 0.2968,
    "60 - 80": 0.2103,
    "80+": 0.0614,
  },
  {
    year: 2012,
    country: "Japan",
    "0 - 20": 0.1794,
    "20 - 40": 0.2442,
    "40 - 60": 0.2619,
    "60 - 80": 0.2467,
    "80+": 0.0677,
  },
  {
    year: 2012,
    country: "Korea, Rep.",
    "0 - 20": 0.2204,
    "20 - 40": 0.2953,
    "40 - 60": 0.3208,
    "60 - 80": 0.142,
    "80+": 0.0215,
  },
  {
    year: 2012,
    country: "Nigeria",
    "0 - 20": 0.5437,
    "20 - 40": 0.2829,
    "40 - 60": 0.1282,
    "60 - 80": 0.0431,
    "80+": 0.0021,
  },
  {
    year: 2012,
    country: "South Africa",
    "0 - 20": 0.3892,
    "20 - 40": 0.3521,
    "40 - 60": 0.1846,
    "60 - 80": 0.0677,
    "80+": 0.0064,
  },
  {
    year: 2012,
    country: "United Kingdom",
    "0 - 20": 0.2369,
    "20 - 40": 0.2665,
    "40 - 60": 0.2673,
    "60 - 80": 0.1814,
    "80+": 0.0479,
  },
  {
    year: 2012,
    country: "United States",
    "0 - 20": 0.2686,
    "20 - 40": 0.268,
    "40 - 60": 0.2715,
    "60 - 80": 0.1549,
    "80+": 0.0371,
  },
  {
    year: 2013,
    country: "China",
    "0 - 20": 0.2472,
    "20 - 40": 0.3133,
    "40 - 60": 0.3006,
    "60 - 80": 0.1242,
    "80+": 0.0147,
  },
  {
    year: 2013,
    country: "Germany",
    "0 - 20": 0.1848,
    "20 - 40": 0.239,
    "40 - 60": 0.3078,
    "60 - 80": 0.2146,
    "80+": 0.0538,
  },
  {
    year: 2013,
    country: "Italy",
    "0 - 20": 0.1868,
    "20 - 40": 0.2389,
    "40 - 60": 0.3002,
    "60 - 80": 0.2113,
    "80+": 0.0628,
  },
  {
    year: 2013,
    country: "Japan",
    "0 - 20": 0.1786,
    "20 - 40": 0.2378,
    "40 - 60": 0.2642,
    "60 - 80": 0.2488,
    "80+": 0.0705,
  },
  {
    year: 2013,
    country: "Korea, Rep.",
    "0 - 20": 0.2138,
    "20 - 40": 0.2917,
    "40 - 60": 0.3253,
    "60 - 80": 0.1462,
    "80+": 0.023,
  },
  {
    year: 2013,
    country: "Nigeria",
    "0 - 20": 0.5442,
    "20 - 40": 0.2822,
    "40 - 60": 0.1287,
    "60 - 80": 0.0429,
    "80+": 0.0021,
  },
  {
    year: 2013,
    country: "South Africa",
    "0 - 20": 0.386,
    "20 - 40": 0.3514,
    "40 - 60": 0.187,
    "60 - 80": 0.069,
    "80+": 0.0066,
  },
  {
    year: 2013,
    country: "United Kingdom",
    "0 - 20": 0.2358,
    "20 - 40": 0.2664,
    "40 - 60": 0.2673,
    "60 - 80": 0.1822,
    "80+": 0.0484,
  },
  {
    year: 2013,
    country: "United States",
    "0 - 20": 0.2653,
    "20 - 40": 0.2693,
    "40 - 60": 0.2692,
    "60 - 80": 0.1591,
    "80+": 0.0372,
  },
  {
    year: 2014,
    country: "China",
    "0 - 20": 0.245,
    "20 - 40": 0.3075,
    "40 - 60": 0.3027,
    "60 - 80": 0.1297,
    "80+": 0.0151,
  },
  {
    year: 2014,
    country: "Germany",
    "0 - 20": 0.1842,
    "20 - 40": 0.2394,
    "40 - 60": 0.3049,
    "60 - 80": 0.2165,
    "80+": 0.0552,
  },
  {
    year: 2014,
    country: "Italy",
    "0 - 20": 0.1854,
    "20 - 40": 0.2346,
    "40 - 60": 0.3032,
    "60 - 80": 0.2125,
    "80+": 0.0643,
  },
  {
    year: 2014,
    country: "Japan",
    "0 - 20": 0.1777,
    "20 - 40": 0.2318,
    "40 - 60": 0.2667,
    "60 - 80": 0.2506,
    "80+": 0.0732,
  },
  {
    year: 2014,
    country: "Korea, Rep.",
    "0 - 20": 0.2073,
    "20 - 40": 0.2886,
    "40 - 60": 0.3285,
    "60 - 80": 0.1513,
    "80+": 0.0244,
  },
  {
    year: 2014,
    country: "Nigeria",
    "0 - 20": 0.5443,
    "20 - 40": 0.2815,
    "40 - 60": 0.1293,
    "60 - 80": 0.0428,
    "80+": 0.0021,
  },
  {
    year: 2014,
    country: "South Africa",
    "0 - 20": 0.3831,
    "20 - 40": 0.3504,
    "40 - 60": 0.1893,
    "60 - 80": 0.0706,
    "80+": 0.0066,
  },
  {
    year: 2014,
    country: "United Kingdom",
    "0 - 20": 0.2347,
    "20 - 40": 0.2664,
    "40 - 60": 0.2671,
    "60 - 80": 0.1832,
    "80+": 0.0487,
  },
  {
    year: 2014,
    country: "United States",
    "0 - 20": 0.262,
    "20 - 40": 0.2706,
    "40 - 60": 0.2666,
    "60 - 80": 0.1635,
    "80+": 0.0373,
  },
  {
    year: 2015,
    country: "China",
    "0 - 20": 0.2429,
    "20 - 40": 0.3026,
    "40 - 60": 0.3043,
    "60 - 80": 0.1349,
    "80+": 0.0154,
  },
  {
    year: 2015,
    country: "Germany",
    "0 - 20": 0.1836,
    "20 - 40": 0.2397,
    "40 - 60": 0.3018,
    "60 - 80": 0.2179,
    "80+": 0.057,
  },
  {
    year: 2015,
    country: "Italy",
    "0 - 20": 0.1839,
    "20 - 40": 0.2307,
    "40 - 60": 0.3055,
    "60 - 80": 0.2141,
    "80+": 0.0659,
  },
  {
    year: 2015,
    country: "Japan",
    "0 - 20": 0.1766,
    "20 - 40": 0.2265,
    "40 - 60": 0.2689,
    "60 - 80": 0.2521,
    "80+": 0.0759,
  },
  {
    year: 2015,
    country: "Korea, Rep.",
    "0 - 20": 0.201,
    "20 - 40": 0.2857,
    "40 - 60": 0.3301,
    "60 - 80": 0.1572,
    "80+": 0.026,
  },
  {
    year: 2015,
    country: "Nigeria",
    "0 - 20": 0.5442,
    "20 - 40": 0.2809,
    "40 - 60": 0.1301,
    "60 - 80": 0.0428,
    "80+": 0.0019,
  },
  {
    year: 2015,
    country: "South Africa",
    "0 - 20": 0.3806,
    "20 - 40": 0.3492,
    "40 - 60": 0.1915,
    "60 - 80": 0.0722,
    "80+": 0.0065,
  },
  {
    year: 2015,
    country: "United Kingdom",
    "0 - 20": 0.2337,
    "20 - 40": 0.2662,
    "40 - 60": 0.2667,
    "60 - 80": 0.1846,
    "80+": 0.0489,
  },
  {
    year: 2015,
    country: "United States",
    "0 - 20": 0.259,
    "20 - 40": 0.2717,
    "40 - 60": 0.2639,
    "60 - 80": 0.1681,
    "80+": 0.0373,
  },
  {
    year: 2016,
    country: "China",
    "0 - 20": 0.241,
    "20 - 40": 0.2987,
    "40 - 60": 0.3052,
    "60 - 80": 0.1389,
    "80+": 0.0163,
  },
  {
    year: 2016,
    country: "Germany",
    "0 - 20": 0.1844,
    "20 - 40": 0.2404,
    "40 - 60": 0.2982,
    "60 - 80": 0.2167,
    "80+": 0.0602,
  },
  {
    year: 2016,
    country: "Italy",
    "0 - 20": 0.1829,
    "20 - 40": 0.2269,
    "40 - 60": 0.3072,
    "60 - 80": 0.215,
    "80+": 0.0681,
  },
  {
    year: 2016,
    country: "Japan",
    "0 - 20": 0.1756,
    "20 - 40": 0.2221,
    "40 - 60": 0.2709,
    "60 - 80": 0.2526,
    "80+": 0.0787,
  },
  {
    year: 2016,
    country: "Korea, Rep.",
    "0 - 20": 0.1954,
    "20 - 40": 0.2823,
    "40 - 60": 0.3308,
    "60 - 80": 0.1635,
    "80+": 0.028,
  },
  {
    year: 2016,
    country: "Nigeria",
    "0 - 20": 0.5444,
    "20 - 40": 0.2801,
    "40 - 60": 0.1307,
    "60 - 80": 0.0427,
    "80+": 0.0021,
  },
  {
    year: 2016,
    country: "South Africa",
    "0 - 20": 0.3782,
    "20 - 40": 0.3485,
    "40 - 60": 0.1931,
    "60 - 80": 0.0733,
    "80+": 0.0069,
  },
  {
    year: 2016,
    country: "United Kingdom",
    "0 - 20": 0.2331,
    "20 - 40": 0.2658,
    "40 - 60": 0.266,
    "60 - 80": 0.1855,
    "80+": 0.0496,
  },
  {
    year: 2016,
    country: "United States",
    "0 - 20": 0.2563,
    "20 - 40": 0.2726,
    "40 - 60": 0.2609,
    "60 - 80": 0.1723,
    "80+": 0.0379,
  },
  {
    year: 2017,
    country: "China",
    "0 - 20": 0.2391,
    "20 - 40": 0.2955,
    "40 - 60": 0.3057,
    "60 - 80": 0.1425,
    "80+": 0.0171,
  },
  {
    year: 2017,
    country: "Germany",
    "0 - 20": 0.1854,
    "20 - 40": 0.241,
    "40 - 60": 0.2946,
    "60 - 80": 0.2155,
    "80+": 0.0635,
  },
  {
    year: 2017,
    country: "Italy",
    "0 - 20": 0.1818,
    "20 - 40": 0.2236,
    "40 - 60": 0.3083,
    "60 - 80": 0.2161,
    "80+": 0.0702,
  },
  {
    year: 2017,
    country: "Japan",
    "0 - 20": 0.1742,
    "20 - 40": 0.2186,
    "40 - 60": 0.2728,
    "60 - 80": 0.253,
    "80+": 0.0814,
  },
  {
    year: 2017,
    country: "Korea, Rep.",
    "0 - 20": 0.1894,
    "20 - 40": 0.2794,
    "40 - 60": 0.3304,
    "60 - 80": 0.1708,
    "80+": 0.03,
  },
  {
    year: 2017,
    country: "Nigeria",
    "0 - 20": 0.5441,
    "20 - 40": 0.2796,
    "40 - 60": 0.1315,
    "60 - 80": 0.0427,
    "80+": 0.0022,
  },
  {
    year: 2017,
    country: "South Africa",
    "0 - 20": 0.3762,
    "20 - 40": 0.3476,
    "40 - 60": 0.1947,
    "60 - 80": 0.0744,
    "80+": 0.0071,
  },
  {
    year: 2017,
    country: "United Kingdom",
    "0 - 20": 0.2326,
    "20 - 40": 0.2654,
    "40 - 60": 0.2651,
    "60 - 80": 0.1869,
    "80+": 0.0501,
  },
  {
    year: 2017,
    country: "United States",
    "0 - 20": 0.2541,
    "20 - 40": 0.2732,
    "40 - 60": 0.2578,
    "60 - 80": 0.1765,
    "80+": 0.0384,
  },
  {
    year: 2018,
    country: "China",
    "0 - 20": 0.2374,
    "20 - 40": 0.2929,
    "40 - 60": 0.3058,
    "60 - 80": 0.1461,
    "80+": 0.0178,
  },
  {
    year: 2018,
    country: "Germany",
    "0 - 20": 0.1865,
    "20 - 40": 0.2415,
    "40 - 60": 0.2909,
    "60 - 80": 0.2146,
    "80+": 0.0665,
  },
  {
    year: 2018,
    country: "Italy",
    "0 - 20": 0.1805,
    "20 - 40": 0.2209,
    "40 - 60": 0.3087,
    "60 - 80": 0.2177,
    "80+": 0.0722,
  },
  {
    year: 2018,
    country: "Japan",
    "0 - 20": 0.1727,
    "20 - 40": 0.2157,
    "40 - 60": 0.2744,
    "60 - 80": 0.2531,
    "80+": 0.0841,
  },
  {
    year: 2018,
    country: "Korea, Rep.",
    "0 - 20": 0.1835,
    "20 - 40": 0.2765,
    "40 - 60": 0.3291,
    "60 - 80": 0.1788,
    "80+": 0.0321,
  },
  {
    year: 2018,
    country: "Nigeria",
    "0 - 20": 0.5434,
    "20 - 40": 0.2792,
    "40 - 60": 0.1324,
    "60 - 80": 0.0427,
    "80+": 0.0022,
  },
  {
    year: 2018,
    country: "South Africa",
    "0 - 20": 0.3744,
    "20 - 40": 0.3463,
    "40 - 60": 0.1965,
    "60 - 80": 0.0755,
    "80+": 0.0072,
  },
  {
    year: 2018,
    country: "United Kingdom",
    "0 - 20": 0.232,
    "20 - 40": 0.2648,
    "40 - 60": 0.2641,
    "60 - 80": 0.1886,
    "80+": 0.0504,
  },
  {
    year: 2018,
    country: "United States",
    "0 - 20": 0.2521,
    "20 - 40": 0.2737,
    "40 - 60": 0.2547,
    "60 - 80": 0.1807,
    "80+": 0.0388,
  },
  {
    year: 2019,
    country: "China",
    "0 - 20": 0.2358,
    "20 - 40": 0.2904,
    "40 - 60": 0.3054,
    "60 - 80": 0.1502,
    "80+": 0.0182,
  },
  {
    year: 2019,
    country: "Germany",
    "0 - 20": 0.1877,
    "20 - 40": 0.2418,
    "40 - 60": 0.2871,
    "60 - 80": 0.2148,
    "80+": 0.0687,
  },
  {
    year: 2019,
    country: "Italy",
    "0 - 20": 0.1791,
    "20 - 40": 0.2186,
    "40 - 60": 0.3085,
    "60 - 80": 0.2201,
    "80+": 0.0738,
  },
  {
    year: 2019,
    country: "Japan",
    "0 - 20": 0.1711,
    "20 - 40": 0.2131,
    "40 - 60": 0.2757,
    "60 - 80": 0.2533,
    "80+": 0.0869,
  },
  {
    year: 2019,
    country: "Korea, Rep.",
    "0 - 20": 0.1782,
    "20 - 40": 0.2732,
    "40 - 60": 0.3274,
    "60 - 80": 0.187,
    "80+": 0.0342,
  },
  {
    year: 2019,
    country: "Nigeria",
    "0 - 20": 0.5424,
    "20 - 40": 0.2791,
    "40 - 60": 0.1335,
    "60 - 80": 0.0429,
    "80+": 0.0022,
  },
  {
    year: 2019,
    country: "South Africa",
    "0 - 20": 0.3727,
    "20 - 40": 0.3445,
    "40 - 60": 0.1988,
    "60 - 80": 0.0768,
    "80+": 0.0072,
  },
  {
    year: 2019,
    country: "United Kingdom",
    "0 - 20": 0.2315,
    "20 - 40": 0.2641,
    "40 - 60": 0.263,
    "60 - 80": 0.1907,
    "80+": 0.0506,
  },
  {
    year: 2019,
    country: "United States",
    "0 - 20": 0.2501,
    "20 - 40": 0.274,
    "40 - 60": 0.2518,
    "60 - 80": 0.1849,
    "80+": 0.0392,
  },
  {
    year: 2020,
    country: "China",
    "0 - 20": 0.2343,
    "20 - 40": 0.2876,
    "40 - 60": 0.3045,
    "60 - 80": 0.155,
    "80+": 0.0185,
  },
  {
    year: 2020,
    country: "Germany",
    "0 - 20": 0.1887,
    "20 - 40": 0.2417,
    "40 - 60": 0.2833,
    "60 - 80": 0.2162,
    "80+": 0.0701,
  },
  {
    year: 2020,
    country: "Italy",
    "0 - 20": 0.1774,
    "20 - 40": 0.2167,
    "40 - 60": 0.3075,
    "60 - 80": 0.2235,
    "80+": 0.0749,
  },
  {
    year: 2020,
    country: "Japan",
    "0 - 20": 0.1696,
    "20 - 40": 0.2103,
    "40 - 60": 0.2768,
    "60 - 80": 0.2535,
    "80+": 0.0898,
  },
  {
    year: 2020,
    country: "Korea, Rep.",
    "0 - 20": 0.1737,
    "20 - 40": 0.2691,
    "40 - 60": 0.3256,
    "60 - 80": 0.1953,
    "80+": 0.0362,
  },
  {
    year: 2020,
    country: "Nigeria",
    "0 - 20": 0.5412,
    "20 - 40": 0.2792,
    "40 - 60": 0.1346,
    "60 - 80": 0.0431,
    "80+": 0.002,
  },
  {
    year: 2020,
    country: "South Africa",
    "0 - 20": 0.3708,
    "20 - 40": 0.3422,
    "40 - 60": 0.2016,
    "60 - 80": 0.0782,
    "80+": 0.0071,
  },
  {
    year: 2020,
    country: "United Kingdom",
    "0 - 20": 0.2311,
    "20 - 40": 0.263,
    "40 - 60": 0.2619,
    "60 - 80": 0.1932,
    "80+": 0.0508,
  },
  {
    year: 2020,
    country: "United States",
    "0 - 20": 0.2479,
    "20 - 40": 0.2742,
    "40 - 60": 0.2491,
    "60 - 80": 0.189,
    "80+": 0.0397,
  },
];
