import React, { useState } from "react";
import AreaChart from "./AreaChart";
import { demographics } from "./AreaDemo";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

// Create a new array of the unique country names
let countries = demographics.map((row) => row.country);
countries = [...new Set(countries)];

export default function Area() {
  const [selectedCountry, setCountry] = useState("Japan");
  return (
    <div class="AChart">
      <style>{`g.visx-group.visx-axis.visx-axis-bottom { display: none }; .visx-legend {display:none}`}</style>
      <h4>Defects per region (Daily)</h4>
      <select
        onChange={(e) => {
          setCountry(e.target.value);
        }}
      >
        {countries.map((country) => (
          <option value={country}>{country}</option>
        ))}
      </select>
      <div
        style={{
          width: "100%",
          height: 500,
        }}
      >
        <ParentSize>
          {({ width, height }) => (
            <AreaChart
              data={demographics.filter(
                (row) => row.country === selectedCountry
              )}
              width={width}
              height={height}
            />
          )}
        </ParentSize>
      </div>
    </div>
  );
}
