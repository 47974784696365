import React, { Component } from "react";
import ParticlesBg from "particles-bg";

class Header extends Component {
  render() {
    // if (this.props.data) {
    //   var project = this.props.data.project;
    //   var github = this.props.data.github;
    //   var name = this.props.data.name;
    //   var description = this.props.data.description;
    //   var city = this.props.data.address.city;
    //   var networks = this.props.data.social.map(function (network) {
    //     return (
    //       <li key={network.name}>
    //         <a href={network.url}>
    //           <i className={network.className}></i>
    //         </a>
    //       </li>
    //     );
    //   });
    // }

    return (
      <header id="home">
        <ParticlesBg type="circle" bg={true} />
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#Inspection">
                Inspect
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#Analytics">
                Analyze
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#Monitoring">
                Monitor
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contactDiv">
                Contact
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <h1 className="responsive-headline">
              Tracking, Analytics & Inspection
            </h1>
            <h3>
              Powerful A.I for industry 4.0 Turfox’s Data-Driven AI Solutions
              gives people & organizations the power to achieve their Goals and
              Vision.
              <br />
              We provide comprehensive Data-Driven Artificial Intelligence
              Solutions.
            </h3>
            <hr />
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#FirstDiv">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
