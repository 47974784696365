import React, { Component } from "react";
// import Racing from "./Racing";
import Area from "./Area";
import LineGraph from "./LineGraph";

class MainContent extends Component {
  render() {
    return (
      <div>
        <section className="wSec" id="FirstDiv">
          <div className="row">
            <div className="adWarp">
              <p>
                Turfox aims to help companies improve product quality, eliminate
                production errors, lower manufacturing costs, and exceed
                customer expectations for high quality products at an affordable
                price. Turfox offers a comprehensive data-driven AI solution.
                Our mission is to give people & organizations the power to
                achieve their Goals and vision.
              </p>
              <h1>Customized Fast Inspection</h1>
              <ul>
                <li>Fast user interaction and data updates</li>
                <li>Customized Inspection</li>
                <li>Customized Integration</li>
                <li>Optimized rendering</li>
                <li>Accuracy powered by Deep Learning</li>
              </ul>
            </div>
            <iframe
              title="Discover Turfox"
              src="https://player.vimeo.com/video/340767867"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* <Racing /> */}
            <p>
              Access, Compare and evaluate data faster and smarter. Give users
              the power to access, evaluate and compare data via intuitive User
              Interface. Our customized systems will enable you to make smarter
              and faster decisions. Strengthen the perception of your brand's
              authenticity and build high quality solutions for your market. We
              offer a truly Multi Product Customized Inspection Solution. A
              fully automated quality assurance and improvement process powered
              by Deep Learning.
            </p>
          </div>
        </section>
        <section className="gSec" id="Analytics">
          <div className="row">
            <div className="adWarp">
              <h1>Fast Analysis using powerful U.I</h1>
              <p>
                Get insights across all of your infrastructure, processes, users
                and employees data to make smarter decisions and act faster
                based on usage facts. Analyze specific events and interaction
                across our organization. Make data-driven decisions to improve
                both productivity and engagement based on factual data with
                powerful customized reporting. Our Artificial Intelligence
                solutions are suited for intelligent industry 4.0. (Automated QA
                powered by Machine Learning & Deep Learning)
              </p>
              <ul>
                <li>Highly composable system</li>
                <li>Avoiding extensive slow DOM manipulation.</li>
                <li>Resolve performance bottle-neck issues in Real-time</li>
                <li>Cross device compatibility</li>
                <li>Remote data managing</li>
              </ul>
            </div>
            <LineGraph />
            <Area />
            {/* <Histo /> */}
          </div>
        </section>
        <section className="wSec" id="xx">
          <div className="row">
            <div className="adWarp">
              <h1>Customized tailored vision solution:</h1>
              <ul>
                <li>No need for production floor replanning</li>
                <li>No need for expensive additional equipment</li>
                <li>No need for additional manpower</li>
              </ul>
            </div>
            <img
              src={process.env.PUBLIC_URL + "/images/customVision.png"}
              id="customVision.png"
              alt="customVision"
            />
          </div>
        </section>
        <section className="gSec" id="automation">
          <div className="row">
            <div className="adWarp">
              <h1>Customized multi product inspection</h1>
              <ul>
                <li>Easy maintenance and updates.</li>
                <li>Extrusion, Compression, Injection Molding, etc.</li>
                <li>Material (plastic, metal, etc)</li>
                <li>Sizes, Structures, Types</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="wSec" id="ML">
          <div className="row">
            <div className="adWarp">
              <h1>AI - POWERFUL MACHINE LEARNING & DEEP LEARNING:</h1>
              <ul>
                <li>Improved accuracy</li>
                <li>Online learning (continuously learning / mini batch)</li>
                <li>Supervised, unsupervised, semisupervised.</li>
                <li>Increased vision depth/scope (720°/360°)</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="gSec" id="auto">
          <div className="row">
            <div className="adWarp">
              <h1>Automation - powered by machine learning</h1>
              <ul>
                <li>Automated illumination using Reinforcement Learning.</li>
                <li>Automated Inspection Cameras (zoom/positioning). </li>
                <li>
                  Automated Image Processing (object detection, filters,
                  cropping, etc).{" "}
                </li>
                <li>6-64 inspection Cameras</li>
                <li>4,000 FPS at HD quality 1280 x 720 (upt to 80,000 fps)</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="wSec" id="Inspection">
          <div className="row">
            <div className="adWarp">
              <h1>CUSTOMIZED INSPECTION CRITERIAS</h1>
              <ul>
                <li>INSERT: Direction, Position.</li>
                <li>
                  BODY: Deformations, Pores, Bubbles, Smears, Burns,
                  Contaminations, Grooves, Scratches, Debris.
                </li>
                <li>CONTOUR: Diameter, Ovality, Areas/Edges, Flash.</li>
                <li>
                  PINHOLES VS DRILLHOLE: Holes/Pinholes (0.02 mm2) minimum size,
                  forms, diameters, distances
                </li>
                <li>COMPLEX STRUCTURES: Advanced Anomaly Detection.</li>
                <li>
                  TOP/SEALING: Flash & Webbing, Ovality, Scratches, Width,
                  Sticking materials.
                </li>
                <li>THIN WALLS / Lines: Side, Base</li>
              </ul>
              <p>
                One of the things that Turfox does especially well is
                customizing vision inspection solutions - quickly &
                cost-effectively - to solve our customer needs. We have worked
                with end users and components manufacturers worldwide to solve
                unique and challenging customized vision applications.
                <br />
                <br />
                Our vision programming solutions are the result of constant
                development, improvement and optimization. They have been
                applied to answer a large number of customized needs. They are
                field tried and hearty. They are field tested and robust. They
                are also extremely powerful and have been optimized to
                automatically take advantage of hyper-threading, multi-core and
                multi-processor computers. And because we have developed all of
                our software ourselves, in-house, it's easy for us to extend and
                adapt it to solve unusual applications.
              </p>
            </div>
          </div>
        </section>
        <section className="gSec" id="inter">
          <div className="row">
            <div className="adWarp">
              <h1>Customized integration & parameters</h1>
              <ul>
                <li>
                  Customized production lines integration (Controllers, Signal
                  tower)
                </li>
                <li>Ready for Industry 4.0 (big data)</li>
                <li>Rejection sensitivity (Areas/Objects)</li>
                <li>Adding / Adjusting products (shapes & color)</li>
                <li>Reporting (Optional)</li>
                <li>Customized Multi-User Permission Access</li>
                <li>Alias Function</li>
                <li>Etc</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="wSec" id="Monitoring">
          <div className="row">
            <div className="adWarp">
              <h1>Monitoring industrial worksites:</h1>
              <ul>
                <li>Ensure employees safety with A.I.</li>
                <li>Automate PPE monitoring</li>
                <li>Easy integration CCTV/IP cameras</li>
                <li>Constant real time monitoring</li>
                <li>Detects absence of protective equipment</li>
                <li>
                  Sends notifications about violations to your safety team
                </li>
                <li>Customized Reporting and analysis</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="gSec" id="xx">
          <div className="row">
            <div className="adWarp">
              <h1>Cost effective dataset solution: Synthetic Data Generator</h1>
              <ul>
                <li>
                  Does not require immense dataset (200 instead of 12,000
                  images)
                </li>
                <li>Algorithm based alternative using unique IP:</li>
                <li>Synthetic data-set generation</li>
                <li>Automatic labeling (supervised DS)</li>
                <li>
                  Online-learning based solution (supervised, unsupervised,
                  semisupervised)
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MainContent;
