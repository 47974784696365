import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryScatter,
  VictoryAxis,
} from "victory";
import theme from "./lineGraphTheme";
export default function LineGraph() {
  return (
    <div className="App">
      <VictoryChart
        theme={theme}
        maxDomain={{ y: 10 }}
        minDomain={{ y: 0 }}
        style={{
          background: {
            fill: "#DFE6EC",
          },

          data: { stroke: "black" },
        }}
        origin={{ x: 20, y: 20 }}
        width={350}
        height={300}
      >
        <VictoryAxis
          tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          dependentAxis
          style={{}}
          theme={theme}
        />
        <VictoryAxis theme={theme} />
        <VictoryScatter
          style={{ data: { fill: "#4FBF92" } }}
          size={4}
          data={[
            { x: "April", y: 7.26 },
            { x: "May", y: 6.28 },
            { x: "June", y: 3.45 },
            { x: "July", y: 2.21 },
            { x: "August", y: 1.23 },
          ]}
        />
        <VictoryLine
          categories={{
            x: [
              "April",
              " ",
              "May",
              "  ",
              "June",
              "   ",
              "July",
              "    ",
              "August",
            ],
            y: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          }}
          padding={{ top: 20, bottom: 60 }}
          labelComponent={<VictoryLabel dy={-0} dx={20} />}
          labels={({ datum }) => datum.y}
          style={{
            data: { stroke: "#4FBF92", strokeWidth: 2 },
            labels: {
              fontSize: 11,
              fill: "#c43a31",
            },
            parent: { rx: 50, ry: 50 },
          }}
          data={[
            { x: "April", y: 7.26 },
            { x: "May", y: 6.28 },
            { x: "June", y: 3.45 },
            { x: "July", y: 2.21 },
            { x: "August", y: 1.23 },
          ]}
          interpolation="catmullRom"
          width={300}
          height={300}
          theme={theme}
        />
      </VictoryChart>
    </div>
  );
}
