import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import Recaptcha from "react-recaptcha";

export default function ContactUs() {
  const [state, setState] = useState({
    recaptchaLoad: false,
    isVerified: false,
  });

  const [errDisplay, setErrDisplay] = useState("none");
  const [sucDisplay, setSucDisplay] = useState("none");

  const recaptchaLoaded = (state) => {
    setState({ ...state, recaptchaLoad: true });
    console.log("was loaded");
  };

  const verifiedRecaptcha = (state, response) => {
    if (response) {
      setState({ ...state, isVerified: true });
      console.log("was ok");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_rtoa4vw",
        e.target,
        "user_Rdyb7dALluzxjqICtf6v5"
      )
      .then(
        (result) => {
          console.log(result.text);
          setErrDisplay("none");
          setSucDisplay("block");
          document.getElementById("user_name").value = "";
          document.getElementById("user_email").value = "";
          document.getElementById("user_message").value = "";
          setTimeout(() => {
            setSucDisplay("none");
          }, 4000);
        },
        (error) => {
          setErrDisplay("block");
          console.log(error.text);
        }
      );
  };

  return (
    <div class="contactUs" id="contactDiv">
      <p id="sucMessage" style={{ display: sucDisplay }}>
        Thank you your message has been sent successfully
      </p>
      <div class="contactInner">
        <h1>Contact</h1>
        <p>
          Turfox gives people & organizations the power to achieve their Goals
          and Vision. We provide comprehensive Data-Driven Artificial
          Intelligence Solutions.
        </p>
        <form className="contact-form" onSubmit={sendEmail}>
          <input type="hidden" name="contact_number" />
          <label>Name</label>
          <input type="text" name="user_name" id="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" id="user_email" />
          <label>Message</label>
          <textarea name="message" id="user_message" />
          <div className="captchaWrap">
            <Recaptcha
              sitekey="6LccEyUTAAAAAGgjYbJ3BK9bh59sLi4unqeo2uoP"
              render="explicit"
              onloadCallback={() => {
                recaptchaLoaded(state);
              }}
              verifyCallback={() => {
                verifiedRecaptcha(state);
              }}
            />
          </div>
          <input type="submit" value="Send" />
          <p id="sendErr" style={{ display: errDisplay }}>
            Please complete all required fields!
          </p>
        </form>
      </div>
    </div>
  );
}
