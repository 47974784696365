import React, { Component } from "react";

class Footer extends Component {
  render() {
    // if (this.props.data) {
    //   var networks = this.props.data.social.map(function (network) {
    //     return (
    //       <li key={network.name}>
    //         <a href={network.url}>
    //           <i className={network.className}></i>
    //         </a>
    //       </li>
    //     );
    //   });
    // }
    const displayYear = new Date().getFullYear();

    return (
      <footer>
        <div className="row">
          <p>© {displayYear} All Rights Reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
